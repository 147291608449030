import { Box, Chip, IconButton, Sheet, Tooltip, Typography } from "@mui/joy";
import { alpha } from "@mui/material";
import { DataGridPremium, useGridApiRef } from "@mui/x-data-grid-premium";
import { Dictionary } from "@reduxjs/toolkit";
import Id from "atoms/Id/Id";
import { Assignment_Entity } from "entities/assignment";
import { Musician } from "entities/musician";
import { ProjectPiece } from "entities/projectPiece";
import { RhapsodyChair } from "entities/rhapsodyChair";
import { Section } from "entities/section";
import { SectionRole } from "entities/sectionRole";
import { ChairTag } from "features/chair/v2/position";
import { useAskQuestion } from "features/context/AskQuestion/AskQuestion";
import MusicianAvatar from "features/musicians/MusicianAvatar/MusicianAvatar";
import { WorkSessionInfo } from "features/workSessions/workSessionInfo";
import { mapToArray } from "helpers";
import { SelectoCheckbox2, useMissionControlModeTint } from "hooks/Layout/v2";
import { useDispatch, useSelector } from "react-redux";
import {
  missionControlModeSelector,
  setFormOpen,
  setMercuryJobIDForHistory,
} from "reducers/rhapsody";
import {
  layoutDebugSelector,
  layoutInternalSelector,
  layoutUtilsSelector,
  selectionSelector,
  setAssignmentIDForLookup,
} from "reducers/v2/missionControl";
import { useUpdateAssignmentMutation } from "redux/assignment/assignmentEndpoints";
import { useSections } from "redux/section/sectionHooks";

/**
 *
 * @returns {ReactElement} Assignments page
 */
export function Assignments() {
  const missionControlMode = useSelector(missionControlModeSelector);
  const {
    assignmentsMap,
    chairs,
    sectionRolesMap,
    musiciansMap,
    workSessions,
    projectPieces,
    workSessionProjectPieces,
  } = useSelector(layoutUtilsSelector);
  const apiRef = useGridApiRef();
  const { sectionsMap } = useSections();
  const [updateAssignment] = useUpdateAssignmentMutation();
  const dispatch = useDispatch();
  const missionControlModeTint = useMissionControlModeTint();
  const debug = useSelector(layoutDebugSelector);
  const assignments = mapToArray(assignmentsMap);

  const onUpdate = async (_new: Assignment_Entity, _old: Assignment_Entity) => {
    return new Promise<Assignment_Entity>((resolve) => {
      const body: any = { memo: _new.memo };
      if (debug) {
        body.importantChanges = _new.importantChanges;
        body.criticalChanges = _new.criticalChanges;
        body.releaseWorkSessionIDs = _new.releaseWorkSessionIDs;
      }
      updateAssignment({
        id: _new.id,
        body,
      });
      setTimeout(() => {
        resolve({ ..._new });
      }, 50);
    });
  };

  function getColumns() {
    const ret = [];

    if (debug) {
      ret.push({
        field: "importantChanges",
        sortable: false,
        headerName: "Revision Important Change",
        width: 220,
        editable: true,
        type: "boolean",
      });

      ret.push({
        field: "criticalChanges",
        sortable: false,
        editable: true,
        headerName: "Revision Critical Change",
        width: 220,
        type: "boolean",
      });

      ret.push({
        field: "releaseWorkSessionIDs",
        sortable: false,
        editable: true,
        headerName: "releaseWorkSessionIDs",
        width: 220,
        type: "string",
      });
    }

    ret.push({
      field: "memo",
      sortable: false,
      headerName: "Assignment Memo",
      width: 220,
      renderHeader: () => (
        <Box>
          <Typography level="body2">Assignment Memo</Typography>
        </Box>
      ),
      renderCell: (c) => (
        <Typography
          level="body4"
          sx={{
            opacity: c.value ? 1 : 0.3,
            color: "black",
            fontWeight: c.value ? 600 : undefined,
            cursor: missionControlMode !== "edit" ? "not-allowed" : undefined,
          }}
        >
          {c.value ? c.value : `Any info to add for this assignment?`}
        </Typography>
      ),
      editable: missionControlMode === "edit",
      type: "string",
    });

    ret.push({
      field: "musicianID",
      sortable: false,
      width: 200,
      headerName: "Musician",
      renderHeader: () => <MusicianHeader />,
      valueGetter: (p) => musiciansMap[p.row.musicianID]?.lastName,
      renderCell: (p) => (
        <MusicianCell
          assignmentID={p.row.id}
          musician={musiciansMap[p.row.musicianID]}
        />
      ),
    });

    workSessions.forEach((w) =>
      ret.push({
        field: `${w.id}`,
        sortable: false,
        headerName: w.title,
        renderHeader: (p) => <WorkSessionInfo workSessionID={w.id} />,
        width: 192,
        align: "center",
        renderCell: (p) => {
          const _chairs = chairs.filter((c) =>
            JSON.parse(p.value).includes(c.id)
          );
          return (
            <ChairsForWorkSessionCell
              chairs={_chairs}
              sectionRolesMap={sectionRolesMap}
              projectPieces={projectPieces}
              sectionsMap={sectionsMap}
            />
          );
        },
      })
    );

    ret.push({
      field: "mercuryJobID",
      headerName: "Status",
      width: 130,
      renderCell: (e) => <MercuryJobStatus id={e.row.id} />,
    });
    ret.push({
      field: "id",
      headerName: "View",
      align: "right",
      width: 70,
      renderCell: (p) => {
        return (
          <Tooltip
            size="sm"
            arrow
            enterDelay={1000}
            enterNextDelay={1000}
            variant="outlined"
            title="Click to view the the Assignment details"
          >
            <IconButton
              onClick={() => {
                dispatch(setAssignmentIDForLookup(p.value));
                dispatch(
                  setFormOpen({ isOpen: true, formID: "assignmentLookup" })
                );
              }}
              size="sm"
              color="neutral"
            >
              <i className="fa-solid fa-circle-info"></i>
            </IconButton>
          </Tooltip>
        );
      },
    });

    return ret;
  }

  const rows = [];

  assignments.forEach((a) => {
    const i = {
      musicianID: a.musicianID,
      id: a.id,
      active: a.active,
      memo: a.memo,
      mercuryJobID: a.mercuryJobID,
      chairCount: 0,
      criticalChanges: a.criticalChanges,
      importantChanges: a.importantChanges,
      releaseWorkSessionIDs: a.releaseWorkSessionIDs,
    };

    workSessions.forEach((w) => {
      const _workSessionProjectPieces = workSessionProjectPieces.filter(
        (wpp) => wpp.workSessionID === w.id
      );
      const projectPieceIDs = _workSessionProjectPieces.reduce((a, v) => {
        a.push(v.projectPieceID);
        return a;
      }, []);
      const _chairs = chairs
        .filter(
          (c) =>
            c.assignmentID === a.id &&
            projectPieceIDs.includes(c.projectPieceID) &&
            c.workSessionIDs.includes(w.id)
        )
        .reduce((a, v) => {
          a.push(v.id);
          return a;
        }, []);
      i.chairCount += _chairs.length;
      i[`${w.id}`] = JSON.stringify(_chairs);
    });
    if (chairs.length) rows.push(i);
  });

  return (
    <Sheet
      sx={{
        p: 1,
        borderRadius: "8px",
        borderColor: alpha(missionControlModeTint, 0.2),
        background: alpha(missionControlModeTint, 0.05),
        backdropFilter: "blur(2px)",
        flex: 1,
      }}
      variant={"outlined"}
    >
      <DataGridPremium
        disableColumnMenu
        disableChildrenSorting
        sx={{ border: "none" }}
        rows={rows.filter((a) => a.active)}
        processRowUpdate={onUpdate}
        apiRef={apiRef}
        columns={getColumns()}
        onCellClick={(e) => {
          if (e.field === "memo") {
            apiRef.current?.startCellEditMode({
              id: e.id,
              field: e.field,
            });
          }
          if (e.field === "mercuryJobID") {
            dispatch(setMercuryJobIDForHistory(e.value as string));
            dispatch(setFormOpen({ isOpen: true, formID: "mercuryHistory" }));
          }
        }}
        initialState={{
          pinnedColumns: {
            left: ["musicianID", "mercuryJobID", "id"],
          },
          sorting: {
            sortModel: [
              {
                field: "musicianID",
                sort: "asc",
              },
            ],
          },
        }}
      />
    </Sheet>
  );
}

function MusicianHeader() {
  const missionControlMode = useSelector(missionControlModeSelector);
  const internal = useSelector(layoutInternalSelector);
  const utils = useSelector(layoutUtilsSelector);
  const assignments = mapToArray(utils?.assignmentsMap);
  const selection = useSelector(selectionSelector);

  const allPositionIDs: string[] = assignments.reduce((a, v) => {
    a.push(...internal.getAssignmentPositionIDs(v.id, utils, selection));

    return a;
  }, []);

  return (
    <Box sx={{ display: "flex", gap: 1 }}>
      {missionControlMode === "invite" ? (
        <SelectoCheckbox2 positionIDs={allPositionIDs} />
      ) : (
        []
      )}
      <Typography level="body2">Musicians</Typography>
    </Box>
  );
}

function MusicianCell({
  musician,
  assignmentID,
}: {
  musician?: Musician;
  assignmentID: number;
}) {
  const missionControlMode = useSelector(missionControlModeSelector);
  const utils = useSelector(layoutUtilsSelector);
  const internal = useSelector(layoutInternalSelector);
  const selection = useSelector(selectionSelector);
  const askQuestion = useAskQuestion();
  const assignment = utils.assignmentsMap[assignmentID];
  const job = utils?.hProject?.jobs.find(
    (j) => j.uuid === assignment?.mercuryJobID
  );
  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
      {missionControlMode === "invite" ? (
        <SelectoCheckbox2
          positionIDs={internal.getAssignmentPositionIDs(
            assignmentID,
            utils,
            selection
          )}
        />
      ) : (
        []
      )}
      <MusicianAvatar noBadge size={28} musician={musician} />
      <Box>
        <Typography
          level="body3"
          sx={{ color: "black", fontWeight: 600, fontSize: 12 }}
        >
          <b>{musician?.fullName()}</b>
        </Typography>
        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          <Id
            tooltip="Assignment ID"
            startDecorator={<i className="fa-regular fa-box-open"></i>}
          >
            {assignmentID}
          </Id>
          {job?.version === 3 ? (
            <Tooltip arrow size="sm" variant="outlined" title="Season Invite">
              <i
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  askQuestion("About Season Invites", ["Got it"], {
                    subtitle: (
                      <Typography>
                        <b>{musician.fullName()}</b> has been contacted for the
                        whole season. This Assignment is controlled by a broader
                        Mission that may contain other Projects. Open the Season
                        | Missions to now more.
                      </Typography>
                    ),
                  });
                }}
                style={{ opacity: 0.58 }}
                className="fa-solid fa-ticket"
              ></i>
            </Tooltip>
          ) : (
            []
          )}
        </Box>
      </Box>
    </Box>
  );
}

function ChairsForWorkSessionCell({
  chairs,
  projectPieces,
  sectionRolesMap,
  sectionsMap,
}: {
  chairs: RhapsodyChair[];
  projectPieces: ProjectPiece[];
  sectionRolesMap: Dictionary<SectionRole>;
  sectionsMap: Dictionary<Section>;
}) {
  const orders = chairs.reduce((a, v) => {
    if (!a.includes(v.order())) a.push(v.order());
    return a;
  }, []);

  const sectionRoleIDs = chairs.reduce((a, v) => {
    if (!a.includes(v.sectionRoleID)) a.push(v.sectionRoleID);
    return a;
  }, []);

  const sectionIDs = chairs.reduce((a, v) => {
    if (!a.includes(v.sectionID)) a.push(v.sectionID);
    return a;
  }, []);

  function getDescription() {
    if (
      orders.length === 1 &&
      sectionRoleIDs.length === 1 &&
      sectionIDs.length === 1
    ) {
      let role = sectionRolesMap[sectionRoleIDs[0]]?.name;
      if (sectionRoleIDs[0] === 4) role = chairs[0].orderString();
      return `${role} ${sectionsMap[sectionIDs[0]]?.name}`;
    }

    if (
      sectionRoleIDs.length > 0 &&
      orders.length === 1 &&
      sectionIDs.length === 1
    ) {
      return `${sectionRoleIDs.length} roles, ${
        sectionsMap[sectionIDs[0]]?.name
      }`;
    }

    if (sectionIDs.length > 0) {
      return sectionIDs
        .reduce((a, v) => {
          a.push(sectionsMap[v]?.name);
          return a;
        }, [])
        .join(", ");
    }

    return "Click for details";
  }

  return (
    <Box>
      {chairs?.length === 0 ? (
        <Typography level="body5">Not Playing</Typography>
      ) : (
        <Typography sx={{ color: "black" }} level="body4">
          {getDescription()}
        </Typography>
      )}
    </Box>
  );
}

// "x positions on x pieces"
// if positions ===1 then "Role || order if section, Violin I on 3 Pieces"
//

function MercuryJobStatus({ id }: { id: string }) {
  const { assignmentsMap, stagesMap } = useSelector(layoutUtilsSelector);
  const assignment = assignmentsMap[id];
  const stage = assignment?.mercuryStageID
    ? stagesMap[assignment?.mercuryStageID]
    : undefined;
  const color = stage?.color ?? "#bdbdbd";
  const background = alpha(color, 0.2);
  const dispatch = useDispatch();
  return (
    <Box
      sx={{
        flex: 1,
        gap: 0.5,
        display: "flex",
        justifyContent: "start",
        width: "100%",
        alignItems: "center",
        cursor: "pointer",
      }}
    >
      <Tooltip
        size="sm"
        arrow
        enterDelay={1000}
        enterNextDelay={1000}
        variant="outlined"
        title="Click to view the history of communication"
      >
        <Chip
          size="sm"
          startDecorator={
            <img
              src={
                stage?.icon ??
                "https://storage.googleapis.com/rhapsody/logos/ready.png"
              }
              style={{ height: 12 }}
            />
          }
          sx={{ background, color }}
        >
          {stage?.wording() ?? "Ready"}
        </Chip>
      </Tooltip>
      {assignment.importantChanges && !stage?.terminus ? (
        <ChairTag
          icon="fa-solid fa-bolt"
          tooltip={
            <Box>
              <Typography level="body3" style={{ textAlign: "center" }}>
                Revision Call needed
              </Typography>
            </Box>
          }
        />
      ) : (
        []
      )}
      {assignment.criticalChanges && !stage?.terminus ? (
        <ChairTag
          icon="fa-solid fa-bolt"
          tooltip={
            <Box>
              <Typography level="body3" style={{ textAlign: "center" }}>
                Revision Call needed
              </Typography>
            </Box>
          }
        />
      ) : (
        []
      )}
    </Box>
  );
}
