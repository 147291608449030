import {
  Button,
  Checkbox,
  Chip,
  ColorPaletteProp,
  IconButton,
  Input,
  Option,
  Select,
  Sheet,
  Switch,
  Textarea,
  Tooltip,
  Typography,
} from "@mui/joy";
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import ColorPicker from "atoms/ColorPicker";
import DialogClose from "atoms/DialogClose/DialogClose";
import Upload from "atoms/FileUpload/FileUpload";
import { Link_Entity } from "entities/link";
import { Project_Entity } from "entities/project";
import { ProjectNote } from "entities/projectNote";
import { useAskQuestion } from "features/context/AskQuestion/AskQuestion";
import MusicianAvatar from "features/musicians/MusicianAvatar";
import { JSONExport } from "features/projects/ProjectMissionControl/jsonExport";
import { RouterConfig } from "hooks/AppRouter/AppRouter";
import { useReadOnly } from "hooks/useReadOnly/useReadOnly";
import { ReactNode, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { convert } from "html-to-text";
import {
  selectedProjectIDSelector,
  setFormOpen,
  setSelectedLinkID,
  setSelectedProjectContactID,
  setSelectedProjectNoteID,
  setSelectedProjectRosterID,
} from "reducers/rhapsody";
import {
  layoutDebugSelector,
  layoutInternalSelector,
  layoutUtilsSelector,
} from "reducers/v2/missionControl";
import {
  useCompanySettings,
  useCurrentCompany,
} from "redux/company/companyHooks";
import { useCustomers } from "redux/customer/customerHooks";
import { useProjectLinks } from "redux/link/linkHooks";
import { useMusicians } from "redux/musician/musicianHooks";
import { usePayrolls } from "redux/payroll/payrollHooks";
import {
  useDeleteProjectMutation,
  useLazyDuplicateProjectQuery,
  useUpdateProjectMutation,
} from "redux/project/projectEndpoints";
import { useProjectContacts } from "redux/projectContact/projectContactHooks";
import { useProjectNotes } from "redux/projectNote/projectNoteHooks";
import { meSelector } from "selectors/me";
import { useProjectRosters } from "redux/projectRoster/projectRosterHooks";
import { RosterOptions } from "hooks/projectRoster/projectRoster";
import { useTag, useTags } from "redux/tag/tagHooks";

/**
 *
 * @returns {ReactElement} ProjectSettings page
 */
export function ProjectSettings() {
  const { project } = useSelector(layoutUtilsSelector);
  const me = useSelector(meSelector);
  const superadmin = me?.services?.account?.indexOf("superadmin") >= 0;

  return (
    <Box sx={{ p: 1, flex: 1, pr: 2 }}>
      <Grid container spacing={2} alignItems={"stretch"}>
        {!project?.template ? (
          <>
            <Grid
              item
              xs={12}
              md={6}
              spacing={1}
              sx={{
                display: "flex",
                gap: 2,
                flexDirection: "column",
              }}
            >
              <ProjectSettingsAbout />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              spacing={1}
              sx={{ display: "flex", gap: 2, flexDirection: "column" }}
            >
              <ProjectWage />
              <ProjectDressCode />
            </Grid>
            <Grid item xs={12}>
              <ProjectContacts />
            </Grid>
            <Grid item xs={12}>
              <ProjectRosters />
            </Grid>
            <Grid item xs={12}>
              <ProjectLinks />
            </Grid>
            <Grid item xs={12}>
              <ProjectNotes />
            </Grid>
          </>
        ) : (
          []
        )}
        <Grid item xs={12}>
          <ProjectExportImport />
        </Grid>
        <Grid item xs={12} sx={{ mb: 2 }}>
          <ProjectDangerZone />
        </Grid>
      </Grid>
    </Box>
  );
}

export function ProjectCover() {
  const projectID = useSelector(selectedProjectIDSelector);
  const utils = useSelector(layoutUtilsSelector);
  const project = utils?.project;
  const [updateProject] = useUpdateProjectMutation();
  const readOnly = useReadOnly();

  return (
    <Box sx={{ display: "flex", gap: 1 }}>
      {project.logoURL ? (
        <img
          src={project.logoURL}
          style={{ width: 95, height: 95, borderRadius: "8px" }}
        />
      ) : (
        <Upload
          disabled={readOnly}
          onUpload={(f) => {
            updateProject({ id: projectID, body: { ...project, logoURL: f } });
          }}
          cropDimension={{ width: 250, height: 250 }}
        >
          <Box
            sx={{
              width: 95,
              height: 95,
              flexShrink: 0,
              border: "dashed 1.5px #9e9e9e",
              borderRadius: "8px",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Typography level="h4" sx={{ color: "#9e9e9e" }}>
              <i className="fa-duotone fa-image"></i>
            </Typography>
          </Box>
        </Upload>
      )}
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        <Typography level="body2" sx={{ color: "black", fontWeight: 600 }}>
          Project Cover
        </Typography>
        <Typography level="body3">
          An artwork respresenting your Project. It must be at least 250px by
          250px with a max size of 512KB. The supported MIME types are JPG, PNG,
          and GIf.
        </Typography>
        <Box sx={{ display: "flex", gap: 1 }}>
          <Upload
            disabled={readOnly}
            className="tour-cover"
            cropDimension={{ width: 250, height: 250 }}
            onUpload={(f) => {
              updateProject({
                id: projectID,
                body: { ...project, logoURL: f },
              });
            }}
          />
          {project.logoURL ? (
            <Chip
              onClick={() =>
                updateProject({
                  id: projectID,
                  body: { ...project, logoURL: "" },
                })
              }
              size="sm"
              variant="outlined"
              color="danger"
            >
              Remove Cover
            </Chip>
          ) : (
            []
          )}
        </Box>
      </Box>
    </Box>
  );
}

export function ProjectSettingsAbout() {
  const projectID = useSelector(selectedProjectIDSelector);
  const utils = useSelector(layoutUtilsSelector);
  const project = utils?.project;
  const { customers } = useCustomers();
  const [_project, setProject] = useState<Project_Entity>(project);
  const [updateProject] = useUpdateProjectMutation();
  const debug = useSelector(layoutDebugSelector);
  const readOnly = useReadOnly();
  const internal = useSelector(layoutInternalSelector);
  const askQuestion = useAskQuestion();
  const save = () => {
    updateProject({ id: projectID, body: { ..._project } });
  };

  useEffect(() => {
    setProject(project);
  }, [project]);

  const confirmNameChange = () => {
    if (internal.callSent) {
      askQuestion("Are you sure?", ["Cancel", "Yes"], {
        subtitle: (
          <Typography>
            The call has been sent. Make sure to share the new Project name with
            musicians to avoid any confusion.
          </Typography>
        ),
      }).then((i) => {
        if (i == 1) {
          updateProject({
            id: project?.id,
            body: { ..._project, name: _project.name },
          });
        } else {
          setProject((p) => ({ ...p, name: project.name }));
        }
      });
    } else {
      updateProject({
        id: project?.id,
        body: { ..._project, name: _project.name },
      });
    }
  };

  return (
    <BoxWrapper>
      <Typography level="h6">About the Project</Typography>
      <ProjectCover />
      <Input
        disabled={readOnly}
        startDecorator="Name:"
        value={_project.name}
        onChange={(e) => setProject((p) => ({ ...p, name: e.target.value }))}
        onBlur={confirmNameChange}
      />
      <Textarea
        disabled={readOnly}
        startDecorator="Internal Memo:"
        value={_project.description}
        placeholder={`Anything to write down about this ${
          project.template ? "Template" : "Project"
        }?`}
        onChange={(e) =>
          setProject((p) => ({ ...p, description: e.target.value }))
        }
        sx={{ width: "100%", background: "#FFFDEF" }}
        onBlur={save}
        endDecorator={
          <Typography level="body4">Not visible by musicians</Typography>
        }
      />
      <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
        <ColorPicker
          disabled={readOnly}
          onChange={(c) => {
            setProject((p) => ({ ...p, color: c }));
            updateProject({
              id: projectID,
              body: { ..._project, color: c },
            });
          }}
          label="Project Color:"
          color={project.color}
        />
        <Typography level="body3">
          Color used for the events in the calendar.
        </Typography>
      </Box>
      {customers?.length ? (
        <Select
          disabled={readOnly}
          onChange={(c, v) => {
            setProject((p) => ({ ...p, customerID: v }));
            updateProject({
              id: projectID,
              body: { ..._project, customerID: v },
            });
          }}
          value={_project.customerID}
          startDecorator={"Customer:"}
        >
          {customers.map((c) => (
            <Option key={c.id} value={c.id}>
              {c.name}
            </Option>
          ))}
        </Select>
      ) : (
        []
      )}
      <Box
        sx={{
          display: "flex",
          gap: 1,
          borderRadius: "8px",
          p: 1,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography startDecorator={<i className="fa-solid fa-eye"></i>}>
            Show full schedule in calls
          </Typography>
          <Typography level="body2">
            If enabled, musicians will see all the Work Sessions the Project
            contains (even the ones they are not in).
          </Typography>
        </Box>
        <Switch
          checked={!_project?.hideWhenNotPlaying}
          onChange={(e) => {
            setProject((pro) => ({
              ...pro,
              hideWhenNotPlaying: !e.target.checked,
            }));
            updateProject({
              id: projectID,
              body: { ..._project, hideWhenNotPlaying: !e.target.checked },
            });
          }}
        />
      </Box>
      {debug ? (
        <Checkbox
          onChange={(e) => {
            setProject((pro) => ({
              ...pro,
              template: e.target.checked,
            }));
            updateProject({
              id: projectID,
              body: { ..._project, template: e.target.checked },
            });
          }}
          checked={_project.template ?? false}
          label="Template"
        />
      ) : (
        []
      )}
    </BoxWrapper>
  );
}

export function ProjectWage() {
  const projectID = useSelector(selectedProjectIDSelector);
  const utils = useSelector(layoutUtilsSelector);
  const readOnly = useReadOnly();
  const project = utils?.project;
  const { payrolls } = usePayrolls();
  const [_project, setProject] = useState<Project_Entity>(project);

  const [updateProject] = useUpdateProjectMutation();

  const save = () => {
    updateProject({ id: projectID, body: { ..._project } });
  };

  useEffect(() => {
    setProject(project);
  }, [project]);

  return (
    <BoxWrapper>
      <Typography
        startDecorator={<i className="fa-solid fa-wallet"></i>}
        level="h6"
      >
        Wage Info
      </Typography>
      <Typography level="body3">
        This info will be included in the call and communication.
      </Typography>
      <Input
        disabled={readOnly}
        startDecorator="Wage:"
        placeholder="ex: $250"
        value={_project.agreement}
        onChange={(e) =>
          setProject((p) => ({ ...p, agreement: e.target.value }))
        }
        onBlur={save}
      />
      <Input
        disabled={readOnly}
        startDecorator="Wage Unit:"
        placeholder="ex: Per service"
        value={_project.wageUnit}
        onChange={(e) =>
          setProject((p) => ({ ...p, wageUnit: e.target.value }))
        }
        onBlur={save}
      />
      <Input
        disabled={readOnly}
        startDecorator="Wage Description:"
        placeholder="ex: OS 200% for Principal"
        value={_project.wageDescription}
        endDecorator={
          <Typography
            color={
              255 - _project.wageDescription.length <= 0 ? "danger" : "neutral"
            }
            level="body4"
          >
            {255 - _project.wageDescription.length} char left
          </Typography>
        }
        onChange={(e) => {
          if (_project.wageDescription.length <= 255)
            setProject((p) => ({ ...p, wageDescription: e.target.value }));
        }}
        onBlur={save}
      />
      {payrolls?.length ? (
        <Select
          disabled={readOnly}
          onChange={(c, v) => {
            setProject((p) => ({ ...p, payrollID: v }));
            updateProject({
              id: projectID,
              body: { ..._project, payrollID: v },
            });
          }}
          value={_project.payrollID}
          startDecorator={"Payroll Company:"}
        >
          {payrolls.map((p) => (
            <Option key={p.id} value={p.id}>
              {p.name}
            </Option>
          ))}
        </Select>
      ) : (
        []
      )}
    </BoxWrapper>
  );
}

export function ProjectContacts() {
  const projectID = useSelector(selectedProjectIDSelector);
  const { projectContacts } = useProjectContacts(projectID);
  const dispatch = useDispatch();
  const readOnly = useReadOnly();
  const musicianIDs = projectContacts.reduce((a, v) => {
    a.push(v.musicianID);
    return a;
  }, []);
  const { musiciansMap } = useMusicians(
    {
      filters: JSON.stringify([
        {
          name: "musicians.id",
          comparison: "in",
          value: musicianIDs,
        },
      ]),
    },
    { skip: musicianIDs?.length === 0 }
  );

  return (
    <BoxWrapper>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
        }}
      >
        <Box>
          <Typography
            startDecorator={<i className="fa-solid fa-address-book"></i>}
            level="h6"
          >
            Contacts
          </Typography>
          <Typography level="body3">
            Include key people from your Address Book (Conductor, Librarian,
            Contractor... etc).
            <br />
            This info will be included in the call and communication.
          </Typography>
        </Box>
        <Tooltip
          title="New Contact"
          variant="outlined"
          arrow
          size="sm"
          enterDelay={1000}
          enterNextDelay={1000}
        >
          <IconButton
            disabled={readOnly}
            onClick={() =>
              dispatch(setFormOpen({ isOpen: true, formID: "projectContact" }))
            }
            variant="solid"
            size="sm"
            color="neutral"
          >
            <i className="fa-solid fa-plus"></i>
          </IconButton>
        </Tooltip>
      </Box>
      {projectContacts.length ? (
        <DataGridPremium
          sx={{ background: "white" }}
          hideFooter
          density="compact"
          rows={projectContacts}
          onRowClick={(e) => {
            dispatch(setSelectedProjectContactID(e.row.id));
            dispatch(setFormOpen({ isOpen: true, formID: "projectContact" }));
          }}
          columns={[
            {
              field: "musicianID",
              headerName: "Person",
              valueGetter: (e) => musiciansMap[e.row.musicianID]?.fullName(),
              width: 250,
              renderCell: (p) => (
                <Typography
                  startDecorator={
                    <MusicianAvatar
                      size={25}
                      musician={musiciansMap[p.row.musicianID]}
                    />
                  }
                  level="body2"
                >
                  {p.value}
                </Typography>
              ),
            },
            { field: "role", headerName: "Role Description", flex: 1 },
          ]}
        />
      ) : (
        []
      )}
    </BoxWrapper>
  );
}

export function ProjectRosters() {
  const readOnly = useReadOnly();
  const projectID = useSelector(selectedProjectIDSelector);
  const { projectRosters } = useProjectRosters(projectID);
  const dispatch = useDispatch();
  return (
    <BoxWrapper>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
        }}
      >
        <Box>
          <Typography
            endDecorator={
              <Chip size="sm" color="info">
                New
              </Chip>
            }
            startDecorator={<i className="fa-regular fa-users"></i>}
            level="h6"
          >
            Live Rosters
          </Typography>
          <Typography level="body3">
            Create a live PDF roster, always up-to-date using a public link.
            <br />
            They can be included in the call and communication.
          </Typography>
        </Box>
        <Tooltip
          title="New Link"
          variant="outlined"
          arrow
          size="sm"
          enterDelay={1000}
          enterNextDelay={1000}
        >
          <IconButton
            disabled={readOnly}
            onClick={() =>
              dispatch(setFormOpen({ isOpen: true, formID: "roster" }))
            }
            variant="solid"
            size="sm"
            color="neutral"
          >
            <i className="fa-solid fa-plus"></i>
          </IconButton>
        </Tooltip>
      </Box>
      {projectRosters.length ? (
        <DataGridPremium
          sx={{ background: "white" }}
          hideFooter
          onRowClick={(r) => {
            dispatch(setSelectedProjectRosterID(r.row.id));
            dispatch(setFormOpen({ isOpen: true, formID: "roster" }));
          }}
          initialState={{
            pinnedColumns: {
              right: ["publicToken", "options"],
            },
          }}
          density="compact"
          rows={projectRosters}
          columns={[
            { field: "name", headerName: "Name", flex: 1 },
            {
              field: "publicToken",
              headerName: "URL",
              align: "right",
              renderCell: (p) => (
                <Tooltip
                  title={p.row.publicUrl()}
                  size="sm"
                  arrow
                  variant="outlined"
                >
                  <Chip
                    endDecorator={
                      <i className="fa-solid fa-arrow-up-right-from-square"></i>
                    }
                    size="sm"
                    variant="outlined"
                    color="neutral"
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      window.open(p.row.publicUrl(), "_blank");
                    }}
                  >
                    Open link
                  </Chip>
                </Tooltip>
              ),
            },
            {
              field: "options",
              headerName: "Active",
              renderCell: (p) => {
                let options: RosterOptions;

                try {
                  options = JSON.parse(p.row.options);
                } catch (error) {}
                return (
                  <Typography color={options.active ? "success" : "danger"}>
                    <i
                      className={
                        options.active
                          ? "fa-duotone fa-solid fa-square-check"
                          : "fa-solid fa-xmark"
                      }
                    ></i>
                  </Typography>
                );
              },
            },
          ]}
        />
      ) : (
        []
      )}
    </BoxWrapper>
  );
}

export function ProjectLinks() {
  const readOnly = useReadOnly();
  const projectID = useSelector(selectedProjectIDSelector);
  const { links } = useProjectLinks(projectID);
  const dispatch = useDispatch();
  return (
    <BoxWrapper>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
        }}
      >
        <Box>
          <Typography
            startDecorator={<i className="fa-solid fa-paperclip"></i>}
            level="h6"
          >
            Links
          </Typography>
          <Typography level="body3">
            Attach links or upload documents for the musicians.
            <br />
            They will be included in the call and communication.
          </Typography>
        </Box>
        <Tooltip
          title="New Link"
          variant="outlined"
          arrow
          size="sm"
          enterDelay={1000}
          enterNextDelay={1000}
        >
          <IconButton
            disabled={readOnly}
            onClick={() =>
              dispatch(setFormOpen({ isOpen: true, formID: "link" }))
            }
            variant="solid"
            size="sm"
            color="neutral"
          >
            <i className="fa-solid fa-plus"></i>
          </IconButton>
        </Tooltip>
      </Box>
      {links.length ? (
        <DataGridPremium
          sx={{ background: "white" }}
          hideFooter
          onRowClick={(r) => {
            dispatch(setSelectedLinkID(r.row.id));
            dispatch(setFormOpen({ isOpen: true, formID: "link" }));
          }}
          initialState={{
            pinnedColumns: {
              right: ["target", "url"],
            },
          }}
          density="compact"
          rows={links}
          columns={[
            { field: "name", headerName: "Name" },
            { field: "description", headerName: "Description", flex: 1 },
            {
              field: "url",
              headerName: "URL",
              width: 100,
              renderCell: (p) => (
                <Tooltip
                  title={`${p.row.url}`}
                  size="sm"
                  arrow
                  variant="outlined"
                >
                  <Chip
                    endDecorator={
                      <i className="fa-solid fa-arrow-up-right-from-square"></i>
                    }
                    size="sm"
                    variant="outlined"
                    color="neutral"
                    onClick={() => window.open(`${p.row.url}`, "_blank")}
                  >
                    Open link
                  </Chip>
                </Tooltip>
              ),
            },
            {
              field: "target",
              headerName: "Who gets it?",
              width: 200,
              renderCell: (p) => <LinkTarget link={p.row as Link_Entity} />,
            },
          ]}
        />
      ) : (
        []
      )}
    </BoxWrapper>
  );
}

export function LinkTarget({ link }: { link: Link_Entity }) {
  const { familiesMap, sectionsMap } = useSelector(layoutUtilsSelector);
  const values =
    link.ties.reduce((a, v) => {
      if (v.modelName === "family") {
        a.push(familiesMap[v.modelID].name);
      }
      if (v.modelName === "section") {
        a.push(sectionsMap[v.modelID].name);
      }
      return a;
    }, []) ?? [];

  return (
    <Tooltip
      title={
        <Box sx={{ maxWidth: 300 }}>
          {values.length ? values?.join(", ") : "Everybody"}
        </Box>
      }
      arrow
      size="sm"
      variant="outlined"
    >
      <Typography level="body2">
        {values?.join(", ")}
        {values.length === 0 ? "Everybody" : ""}
      </Typography>
    </Tooltip>
  );
}

export function ProjectNotes() {
  const projectID = useSelector(selectedProjectIDSelector);
  const readOnly = useReadOnly();
  const { projectNotes } = useProjectNotes(projectID);
  const dispatch = useDispatch();
  return (
    <BoxWrapper>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
        }}
      >
        <Box>
          <Typography
            startDecorator={<i className="fa-solid fa-pen-nib"></i>}
            level="h6"
          >
            Notes
          </Typography>
          <Typography level="body3">Add freeform notes to the call.</Typography>
        </Box>
        <Tooltip
          title="New Note"
          variant="outlined"
          arrow
          size="sm"
          enterDelay={1000}
          enterNextDelay={1000}
        >
          <IconButton
            disabled={readOnly}
            onClick={() =>
              dispatch(setFormOpen({ isOpen: true, formID: "projectNote" }))
            }
            variant="solid"
            size="sm"
            color="neutral"
          >
            <i className="fa-solid fa-plus"></i>
          </IconButton>
        </Tooltip>
      </Box>
      {projectNotes.length ? (
        <DataGridPremium
          sx={{ background: "white" }}
          hideFooter
          onRowClick={(r) => {
            dispatch(setSelectedProjectNoteID(r.row.id));
            dispatch(setFormOpen({ isOpen: true, formID: "projectNote" }));
          }}
          density="compact"
          initialState={{
            pinnedColumns: {
              right: ["target", "url"],
            },
          }}
          rows={projectNotes}
          columns={[
            { field: "name", headerName: "Name" },
            {
              field: "content",
              headerName: "Content",
              flex: 1,
              renderCell: (p) => {
                return convert(p.row.value, {});
              },
            },
            {
              field: "target",
              headerName: "Who gets it?",
              width: 200,
              renderCell: (p) => {
                return <WhoGetsNote note={p.row} />;
              },
            },
            {
              field: "url",
              headerName: "Auto Include",
              width: 100,
              align: "right",
              renderCell: (p) => (
                <Typography color="success">
                  {p.row.include ? (
                    <i className="fa-duotone fa-solid fa-square-check"></i>
                  ) : (
                    []
                  )}
                </Typography>
              ),
            },
          ]}
        />
      ) : (
        []
      )}
    </BoxWrapper>
  );
}

export function WhoGetsNote({ note }: { note: ProjectNote }) {
  const utils = useSelector(layoutUtilsSelector);
  let ret = [];
  const roleIDs = note.roles.split(",");
  const sectionIDs = note.sections.split(",");
  const stageIDs = note.stages.split(",");

  roleIDs.forEach((r) => ret.push(utils.sectionRolesMap[r]?.name));
  sectionIDs.forEach((r) => ret.push(utils.sectionsMap[r]?.name));
  stageIDs.forEach((r) => ret.push(utils.stagesMap[r]?.name));

  ret = ret.filter((e) => e);

  if (!note.include) return <Box />;

  return (
    <Tooltip
      title={<Box sx={{ maxWidth: 300 }}>{ret.join(", ")}</Box>}
      arrow
      size="sm"
      variant="outlined"
    >
      <Box>{ret.length > 0 ? ret.join(", ") : "Everybody"}</Box>
    </Tooltip>
  );
}

export function ProjectDressCode() {
  const projectID = useSelector(selectedProjectIDSelector);
  const utils = useSelector(layoutUtilsSelector);
  const readOnly = useReadOnly();
  const dispatch = useDispatch();
  const project = utils?.project;
  const settings = useCompanySettings();
  const [_project, setProject] = useState<Project_Entity>(project);

  const [updateProject] = useUpdateProjectMutation();

  const save = () => {
    updateProject({ id: projectID, body: { ..._project } });
  };

  useEffect(() => {
    setProject(project);
  }, [project]);

  const dressCodePreset = settings?.defaultDressCodes?.find(
    (e) => e.content === _project.dressCode
  );

  return (
    <BoxWrapper>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography
            startDecorator={<i className="fa-solid fa-clothes-hanger"></i>}
            level="h6"
          >
            Dress Code
          </Typography>
          <Typography level="body3">
            This info will be included in the call and communication.
          </Typography>
        </Box>
        <Button
          size="sm"
          startDecorator={<i className="fa-solid fa-pen"></i>}
          disabled={readOnly}
          onClick={() =>
            dispatch(setFormOpen({ isOpen: true, formID: "projectDressCode" }))
          }
        >
          Edit
        </Button>
      </Box>
      <Select
        slotProps={{
          listbox: { sx: { zIndex: 9999 } },
        }}
        startDecorator={
          <Typography level="body2">
            <b>Use Snippet:</b>
          </Typography>
        }
        size="sm"
        variant="outlined"
        placeholder={
          dressCodePreset ? dressCodePreset?.name : "Select a Snippet"
        }
        value={""}
        onChange={(e, v) => {
          if (v) {
            setProject((p) => ({ ...p, dressCode: v }));
            updateProject({
              id: projectID,
              body: { ..._project, dressCode: v },
            });
          }
        }}
      >
        {settings.defaultDressCodes?.map((o) => (
          <Option key={o.content} value={o.content}>
            {o.name}
          </Option>
        ))}
        <Option
          onClick={() =>
            dispatch(setFormOpen({ isOpen: true, formID: "defaultDressCodes" }))
          }
          value="create"
          color="primary"
        >
          <b>+ Create Snippet</b>
        </Option>
      </Select>
      {_project.dressCode ? (
        <Sheet
          variant="outlined"
          onClick={() =>
            dispatch(setFormOpen({ isOpen: true, formID: "projectDressCode" }))
          }
          sx={{
            p: 1,
            borderRadius: "8px",
            maxHeight: 300,
            overflow: "auto",
            cursor: "pointer",
          }}
        >
          <div
            style={{ marginTop: 4 }}
            dangerouslySetInnerHTML={{
              __html: _project.dressCode,
            }}
          />
        </Sheet>
      ) : (
        []
      )}
    </BoxWrapper>
  );
}

export function ProjectExportImport() {
  return (
    <BoxWrapper>
      <Typography
        startDecorator={<i className="fa-solid fa-arrow-up-arrow-down"></i>}
        level="h6"
      >
        Export
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          flex: 1,
          width: "100%",
          alignItems: "start",
        }}
      >
        <JSONExport />
        {/* <JSONImport /> */}
      </Box>
    </BoxWrapper>
  );
}

export function ProjectDangerZone() {
  const utils = useSelector(layoutUtilsSelector);
  const readOnly = useReadOnly();
  const project = utils?.project;
  const [updateProject] = useUpdateProjectMutation();
  const [confirm, setConfirm] = useState(false);
  const { tags } = useTags();
  const [projectName, setProjectName] = useState("");
  const { company } = useCurrentCompany();
  const [deleteProject] = useDeleteProjectMutation();
  const [duplicateProject] = useLazyDuplicateProjectQuery();
  const askQuestion = useAskQuestion();
  const navigate = useNavigate();
  const debugMode = useSelector(layoutDebugSelector);

  return (
    <BoxWrapper color="danger">
      <Typography level="h6" color="danger">
        Danger Zone
      </Typography>
      <Box sx={{ display: "flex", gap: 1, alignItems: "start" }}>
        <Select
          disabled={readOnly}
          sx={{ width: 200 }}
          color="danger"
          startDecorator="Season:"
          value={project.pipelineID}
          onChange={(e, v) => {
            updateProject({
              id: project.id,
              body: {
                ...project,
                pipelineID: v,
              },
            });
          }}
        >
          {tags?.map((p) => (
            <Option value={p.id} key={p.id}>
              {p.name}
            </Option>
          ))}
        </Select>
        <Typography level="body3" color="danger">
          Select the Season this project belongs in
        </Typography>
      </Box>
      {!project?.template ? (
        <>
          {debugMode ? (
            <Box sx={{ display: "flex", gap: 1, alignItems: "start" }}>
              <Select
                disabled={readOnly}
                sx={{ width: 200 }}
                color="danger"
                startDecorator="Pipeline:"
                value={project.pipelineID}
                onChange={(e, v) => {
                  updateProject({
                    id: project.id,
                    body: {
                      ...project,
                      pipelineID: v,
                    },
                  });
                }}
              >
                {company?.pipelines?.map((p) => (
                  <Option value={p.id} key={p.id}>
                    {p.name}
                  </Option>
                ))}
              </Select>
              <Typography level="body3" color="danger">
                Select the Mercury Pipeline you would like to use for this
                project.
              </Typography>
            </Box>
          ) : (
            []
          )}
          <Box sx={{ display: "flex", gap: 1, alignItems: "start" }}>
            <Button
              disabled={readOnly}
              sx={{ flexShrink: 0, width: 200, background: "white" }}
              color="danger"
              variant="outlined"
              startDecorator={<i className="fa-solid fa-copy"></i>}
              onClick={() => {
                askQuestion("Are you sure?", ["Cancel", "Yes"], {
                  subtitle: (
                    <Typography>
                      You are about to duplicate this project. You will be
                      redirected to the new instance automatically. Please
                      confirm.
                    </Typography>
                  ),
                }).then((i) => {
                  if (i == 1) {
                    duplicateProject(project.id)
                      .unwrap()
                      .then((e) => {
                        navigate(
                          `${RouterConfig.projects}/${e.id}/mission-control`
                        );
                      });
                  }
                });
              }}
            >
              Duplicate Project
            </Button>
            <Typography level="body3" color="danger">
              This action will create a new project. Chairs, Work Sessions &
              Project Pieces will also be duplicated.
            </Typography>
          </Box>
        </>
      ) : (
        []
      )}
      <Box sx={{ display: "flex", gap: 1, alignItems: "start" }}>
        <Button
          disabled={readOnly}
          startDecorator={<i className="fa-solid fa-box-archive"></i>}
          sx={{ flexShrink: 0, width: 200, background: "white" }}
          color="danger"
          variant="outlined"
          onClick={() => {
            updateProject({
              id: project.id,
              body: {
                ...project,
                archived: !project.archived,
              },
            });
          }}
        >
          {project.archived
            ? `Unarchive ${project.template ? "Template" : "Project"}`
            : `Archive ${project.template ? "Template" : "Project"}`}
        </Button>
        {}
        <Typography level="body3" color="danger">
          {project.template
            ? "The Template will be moved to the Archive tab on the main list. You will be able to access the Template and un-archive it."
            : "We recommand to archive Project when it is done. The Project will be moved to the Archive tab on the main list. You will be able to access the Project and un-archive it."}
          <br />
        </Typography>
      </Box>
      <Box sx={{ display: "flex", gap: 1, alignItems: "start" }}>
        <Tooltip
          arrow
          variant="outlined"
          title={
            !project.archived
              ? "Archive the project first in order to delete it"
              : undefined
          }
        >
          <Box>
            <Button
              sx={{ flexShrink: 0, width: 200 }}
              color="danger"
              startDecorator={<i className="fa-solid fa-trash"></i>}
              variant="solid"
              disabled={!project.archived || readOnly}
              onClick={() => setConfirm(true)}
            >
              Delete {project.template ? "Template" : "Project"}
            </Button>
          </Box>
        </Tooltip>
        <Typography level="body3" color="danger">
          {project.template
            ? "The Template will be deleted forever. There is no way back. Use with extreme caution."
            : "Everything about this Project will be deleted forever. If musicians have been contacted, they won't be notified about this deletion. There is no way back. Use with extreme caution."}
        </Typography>
      </Box>
      <DialogClose open={confirm} onClose={() => setConfirm(false)}>
        <DialogTitle>One last time: Are you sure?</DialogTitle>
        <DialogContent>
          <Typography level={"body2"}>
            Please confirm this un-recoverable action by writting the name of
            the {project.template ? "Template" : "Project"}:
          </Typography>
          <Input
            autoFocus
            value={projectName}
            onChange={(e) => setProjectName(e.target.value)}
            placeholder={project.name}
          />
        </DialogContent>
        <DialogActions>
          <Button color="neutral" variant="soft">
            Cancel
          </Button>
          <Button
            onClick={() => {
              deleteProject(project.id);
              setTimeout(() => {
                navigate(RouterConfig.projects);
              }, 1000);
            }}
            color="danger"
            disabled={project.name !== projectName}
          >
            Delete forever
          </Button>
        </DialogActions>
      </DialogClose>
    </BoxWrapper>
  );
}

export function BoxWrapper({
  children,
  color = "neutral",
}: {
  children: ReactNode;
  color?: ColorPaletteProp;
}) {
  return (
    <Sheet
      variant="soft"
      color={color}
      sx={{
        p: 2,
        borderRadius: "16px",
        display: "flex",
        flexDirection: "column",
        gap: 1,
        flex: 1,
      }}
    >
      {children}
    </Sheet>
  );
}
