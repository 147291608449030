import { Box, Button, Chip, Textarea, Typography } from "@mui/joy";
import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import {
  LocalizationProvider,
  StaticDateRangePicker,
} from "@mui/x-date-pickers-pro";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import DialogClose from "atoms/DialogClose/DialogClose";
import { MusicianHoliday } from "entities/musicianHoliday";
import { useAskQuestion } from "features/context/AskQuestion/AskQuestion";
import MusicianAvatar from "features/musicians/MusicianAvatar";
import { useReadOnly } from "hooks/useReadOnly/useReadOnly";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  formOpenSelector,
  selectedMusicianHolidayIDSelector,
  selectedMusicianIDSelector,
  setFormOpen,
} from "reducers/rhapsody";
import { useCompanySettings } from "redux/company/companyHooks";
import { useMusician } from "redux/musician/musicianHooks";
import {
  useCreateMusicianHolidayMutation,
  useDeleteMusicianHolidayMutation,
  useUpdateMusicianHolidayMutation,
} from "redux/musicianHoliday/musicianHolidayEndpoints";
import { useMusicianHoliday } from "redux/musicianHoliday/musicianHolidayHooks";

/**
 *
 * @returns {ReactElement} MusicianTimeOff page
 */
export function MusicianHolidayForm() {
  const dispatch = useDispatch();
  const open = useSelector(formOpenSelector("musicianHoliday"));
  const musicianID = useSelector(selectedMusicianIDSelector);
  const selectedMusicianHolidayID = useSelector(
    selectedMusicianHolidayIDSelector
  );
  const askQuestion = useAskQuestion();
  const { musician } = useMusician(musicianID);
  const { musicianHoliday } = useMusicianHoliday(selectedMusicianHolidayID);
  const [holiday, setHoliday] = useState<Partial<MusicianHoliday>>({});
  const readOnly = useReadOnly();
  const settings = useCompanySettings();
  const [createMusicianHoliday] = useCreateMusicianHolidayMutation();
  const [updateMusicianHoliday] = useUpdateMusicianHolidayMutation();
  const [deleteMusicianHoliday] = useDeleteMusicianHolidayMutation();
  const onClose = () => {
    dispatch(setFormOpen({ isOpen: false, formID: "musicianHoliday" }));
    setHoliday({});
  };

  useEffect(() => {
    if (musicianHoliday?.id) setHoliday(musicianHoliday);
  }, [musicianHoliday]);

  useEffect(() => {
    moment.updateLocale("en", {
      week: {
        dow: settings.calendarFirstDay
          ? parseInt(settings.calendarFirstDay)
          : 0,
      },
    });
  }, [settings]);

  const save = () => {
    if (holiday.id) {
      updateMusicianHoliday({
        id: holiday.id,
        body: { ...holiday },
      });
    } else {
      createMusicianHoliday({ ...holiday, musicianID: musicianID });
    }
    onClose();
  };

  const askDelete = () => {
    askQuestion("Are you sure?", ["Cancel", "Yes"], {
      subtitle: (
        <Typography>
          You are about to delete a musician's time-off, please confirm
        </Typography>
      ),
    }).then((i) => {
      if (i == 1) confirmDelete();
    });
  };

  const confirmDelete = async () => {
    deleteMusicianHoliday(selectedMusicianHolidayID);

    onClose();
  };

  const kinds = [
    "Vacation",
    "Sick Leave",
    "Family Leave",
    "Sabbatical",
    "Jury Duty",
    "Personal Leave",
    "Educational Leave",
  ];

  return (
    <DialogClose
      slotProps={{
        paper: { style: { width: 400 } },
      }}
      fullWidth
      maxWidth="md"
      onClose={onClose}
      open={open}
    >
      <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          Musician Time Off
          <Typography level="body2">
            Musician will be marked as unavailable through Rhapsody for the
            selected dates
          </Typography>
        </Box>
        <Typography
          startDecorator={<MusicianAvatar musician={musician} size={24} />}
        >
          {musician?.fullName()}
        </Typography>
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 1,
        }}
      >
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <StaticDateRangePicker
            value={[
              holiday.dateFromUTC ? moment(holiday.dateFromUTC) : null,
              holiday.dateToUTC ? moment(holiday.dateToUTC) : null,
            ]}
            onChange={(e) => {
              console.log(e);
              setHoliday((h) => ({
                ...h,
                dateFromUTC: e[0]?.utc()?.format(),
                dateToUTC: e[1]?.utc()?.format(),
              }));
            }}
            displayWeekNumber
            calendars={2}
            slotProps={{ actionBar: { actions: [] } }}
          />
        </LocalizationProvider>
        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          <Typography level="body2">Type: </Typography>
          {kinds.map((k) => (
            <Chip
              size="sm"
              onClick={() => {
                setHoliday((h) => ({ ...h, kind: k }));
              }}
              variant={holiday?.kind === k ? "solid" : "soft"}
              color={holiday?.kind === k ? "primary" : "neutral"}
              key={k}
            >
              {k}
            </Chip>
          ))}
        </Box>
        <Textarea
          placeholder={`Comments`}
          sx={{ width: "100%", background: "#FFFDEF", maxWidth: 600 }}
          onChange={(e) =>
            setHoliday((h) => ({ ...h, comments: e.target.value }))
          }
          disabled={readOnly}
          value={holiday.comments ?? ""}
          size="sm"
          minRows={5}
          maxRows={10}
          endDecorator={
            <Typography level="body4">
              Not visible by {musician?.fullName()}
            </Typography>
          }
        />
      </DialogContent>
      <DialogActions>
        {selectedMusicianHolidayID ? (
          <Button color="danger" variant="soft" onClick={askDelete}>
            Delete
          </Button>
        ) : (
          []
        )}
        {selectedMusicianHolidayID ? <Box sx={{ flexGrow: 1 }} /> : []}
        <Button color="neutral" variant="soft" onClick={onClose}>
          Close
        </Button>
        <Button onClick={save}>
          {selectedMusicianHolidayID ? "Update" : "Create"}
        </Button>
      </DialogActions>
    </DialogClose>
  );
}
