import {
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  IconButton,
  List,
  ListItemButton,
  Textarea,
  Typography,
} from "@mui/joy";
import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import DialogClose from "atoms/DialogClose/DialogClose";
import { useUpdater } from "features/chair/v2/useUpdater";
import MusicianAvatar from "features/musicians/MusicianAvatar";
import { mapToArray } from "helpers";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  formOpenSelector,
  notifierBodySelector,
  selectedProjectIDSelector,
  setFormOpen,
  setNotifier2,
  setNotifierBody,
} from "reducers/rhapsody";
import {
  layoutInternalSelector,
  layoutUtilsSelector,
  positionsSelectedSelector,
  selectPositions,
  selectionSelector,
  setAssignmentIDForRelease,
} from "reducers/v2/missionControl";
import { rhapsodyApi } from "redux/api";
import { useUpdateAssignmentMutation } from "redux/assignment/assignmentEndpoints";
import { useCreateInspectorMutation } from "redux/inspector/inspectorEndpoints";
import { useLazyGetUpcomingStagesQuery } from "redux/projectMercury/projectMercuryEndpoints";

/**
 *
 * @returns {ReactElement} ReleaseRequest page
 */
export function ReleaseAdmin() {
  const open = useSelector(formOpenSelector("releaseAdmin"));
  const selecto = useSelector(positionsSelectedSelector);
  const dispatch = useDispatch();
  const [workSessionIDs, setWorkSessionIDs] = useState<number[]>([]);
  const [projectPieceIDs, setProjectPieceIDs] = useState<number[]>([]);
  const layoutUtils = useSelector(layoutUtilsSelector);
  const selection = useSelector(selectionSelector);
  const { projectPieces, workSessions } = layoutUtils;
  const [releaseFrom, setReleaseFrom] = useState<
    "Project Piece(s)" | "Work Session(s)"
  >();
  const internal = useSelector(layoutInternalSelector);
  const [reason, setReason] = useState("");
  const { workSessionIDs: selectedWorkSessionIDs } =
    useSelector(selectionSelector);
  const body = useSelector(notifierBodySelector);
  const [createInspector] = useCreateInspectorMutation();
  const utils = useSelector(layoutUtilsSelector);
  const { hProject, piecesMap, assignments, chairs } = utils;
  const { releasableMusicians, visibleAssignmentIDs } = selecto;
  const [getUpcomingStages] = useLazyGetUpcomingStagesQuery();
  const projectID = useSelector(selectedProjectIDSelector);
  const updater = useUpdater(
    selecto.positionIDs,
    visibleAssignmentIDs,
    internal,
    layoutUtils
  );

  const releasableWorkSessions = workSessions.filter((w) => {
    const firstAssignmentID = visibleAssignmentIDs.length
      ? visibleAssignmentIDs[0]
      : undefined;
    if (firstAssignmentID) {
      const _chairs = chairs.filter(
        (c) =>
          c.workSessionIDs.includes(w.id) &&
          c.assignmentID === firstAssignmentID
      );
      return _chairs.length > 0;
    }
    return false;
  });

  useEffect(() => {
    setWorkSessionIDs(
      selectedWorkSessionIDs.filter((w) =>
        releasableWorkSessions.find((e) => e.id === w)
      )
    );
  }, [selectedWorkSessionIDs]);

  useEffect(() => {
    if (projectPieces.length === 1) {
      setReleaseFrom("Work Session(s)");
    }
  }, [projectPieces]);

  // useEffect(() => {
  //   if (positions.length) {
  //     setWorkSessionIDs(
  //       positions.reduce((a, v) => {
  //         if (v.forWorkSessionID) {
  //           if (!a.includes(v.forWorkSessionID)) a.push(v.forWorkSessionID);
  //         } else {
  //           const chairIDs = v.chairIDs;
  //           chairIDs.forEach((c) => {
  //             const _chair = layoutUtils.chairsMap[c];
  //             _chair?.workSessionIDs.forEach((w) => {
  //               if (!a.includes(w)) a.push(w);
  //             });
  //           });
  //         }
  //         return a;
  //       }, [])
  //     );
  //   }
  // }, [positions]);

  const onClose = () => {
    dispatch(setAssignmentIDForRelease());
    setWorkSessionIDs([]);
    setProjectPieceIDs([]);
    dispatch(setFormOpen({ isOpen: false, formID: "releaseAdmin" }));
  };

  const releaseFromWorkSessionAction = async () => {
    releasableMusicians.forEach((m) => {
      const assignment = assignments.find((a) => a.musicianID === m.id);
      const job = hProject.jobs.find((j) => j.uuid === assignment.mercuryJobID);
      const currentStage = job.currentStage;
      createInspector({
        jobStageID: currentStage.relationID,
        message: `Released from Work Sessions (${workSessionIDs}):\r\n${reason}`,
        icon: "fa-solid fa-person-from-portal",
        kind: "release",
        title: "Release",
      }).then(() => dispatch(rhapsodyApi.util.invalidateTags(["mercuryJobs"])));
    });

    if (releasableWorkSessions.length === workSessionIDs.length) {
      sendMessage("", utils.releaseStage?.id ?? utils.messageStage?.id);
      // sendMessage(
      //   `You have been released from the Project.\r\n${reason}`, // Side effect!
      //   RELEASE_ID
      // );
    } else {
      await updater.handleAddWorkSessionChairsForReleasableMusicians(
        workSessionIDs
      );
      sendMessage("", utils.revisionStage?.id ?? utils.messageStage?.id);
      // sendMessage(
      //   `You have been released for ${workSessionIDs.length} Work Session${
      //     workSessionIDs.length > 1 ? "s" : ""
      //   }.\r\n${reason}`
      // );
    }
    onClose();
  };

  const releaseFromProjectPieceAction = async () => {
    releasableMusicians.forEach((m) => {
      const assignment = assignments.find((a) => a.musicianID === m.id);
      const job = hProject.jobs.find((j) => j.uuid === assignment.mercuryJobID);
      const currentStage = job.currentStage;
      createInspector({
        jobStageID: currentStage.relationID,
        message: `Released from Project Piece (${projectPieceIDs}):\r\n${reason}`,
        icon: "fa-solid fa-person-from-portal",
        kind: "release",
        title: "Release",
      }).then(() => dispatch(rhapsodyApi.util.invalidateTags(["mercuryJobs"])));
    });
    await updater.handleRemoveFromPieceForReleasableMusicians(projectPieceIDs);
    sendMessage("", utils.revisionStage?.id ?? utils.messageStage?.id);
    // sendMessage(
    //   `You have been released from ${projectPieceIDs.length} Piece${
    //     projectPieceIDs.length > 1 ? "s" : ""
    //   }.\r\n${reason}`
    // );
    onClose();
  };

  const sendMessage = async (greeting: string, stageID?: number) => {
    const musicianIDs = releasableMusicians.reduce((a, v) => {
      a.push(v.id);
      return a;
    }, []);

    const assignments = mapToArray(layoutUtils.assignmentsMap).filter((a) =>
      musicianIDs.includes(a.musicianID)
    );

    const positionIDs: string[] = [];

    for (const key in assignments) {
      if (Object.hasOwnProperty.call(assignments, key)) {
        const a = assignments[key];
        const positionIDs = internal.getAssignmentPositionIDs(
          a.id,
          layoutUtils,
          selection
        );
        positionIDs.forEach((positionID) => {
          if (!positionIDs.includes(positionID)) positionIDs.push(positionID);
        });
      }
    }
    dispatch(rhapsodyApi.util.invalidateTags(["assignments"]));
    dispatch(selectPositions(positionIDs));
    dispatch(setNotifierBody({ ...body, greeting }));
    const { data } = await getUpcomingStages({
      id: projectID,
      body: {
        musicianIDs,
      },
    });
    dispatch(
      setNotifier2({
        selectedStageID: stageID ?? utils.revisionStage?.id,
        hProject: data,
      })
    );
    dispatch(setFormOpen({ formID: "notifier2", isOpen: true }));
  };

  const releaseFromContent = (
    <>
      <Typography level="body2">Excuse from:</Typography>
      <Box sx={{ display: "flex", gap: 1 }}>
        {projectPieces.length > 1 ? (
          <IconButton
            onClick={() => setReleaseFrom("Project Piece(s)")}
            color="neutral"
            sx={{ p: 2 }}
            size="lg"
          >
            <Box>
              <Typography level="h4" sx={{ color: "inherit" }}>
                <i className="fa-solid fa-feather"></i>
              </Typography>
              <Typography sx={{ color: "inherit" }}>
                Project Piece(s)
              </Typography>
            </Box>
          </IconButton>
        ) : (
          []
        )}
        <IconButton
          onClick={() => setReleaseFrom("Work Session(s)")}
          color="neutral"
          sx={{ p: 2 }}
          size="lg"
        >
          <Box>
            <Typography level="h4" sx={{ color: "inherit" }}>
              <i className="fa-solid fa-calendar-day"></i>
            </Typography>
            <Typography sx={{ color: "inherit" }}>Work Session(s)</Typography>
          </Box>
        </IconButton>
      </Box>
    </>
  );

  const releaseFromWorkSession = (
    <>
      <Textarea
        startDecorator={<Typography>Reason:</Typography>}
        autoFocus
        value={reason}
        onChange={(e) => setReason(e.target.value)}
        variant="soft"
        placeholder="Explanation"
        endDecorator={
          <Typography level="body4">
            This message will appear in the musician's event history.
          </Typography>
        }
      />
      <Typography level="body2">
        Confirm the Work Session(s) you wish to excuse the musician(s):
      </Typography>
      <List>
        {workSessions.map((e) => {
          const disabled = !releasableWorkSessions.includes(e);
          return (
            <ListItemButton
              key={e.id}
              sx={{ gap: 1, opacity: disabled ? 0.3 : 1 }}
              disabled={disabled}
              onClick={() => {
                if (workSessionIDs.includes(e.id)) {
                  setWorkSessionIDs((w) => w.filter((z) => z !== e.id));
                } else {
                  setWorkSessionIDs((w) => [...w, e.id]);
                }
              }}
            >
              <Checkbox
                disabled={disabled}
                color={disabled ? "neutral" : "primary"}
                checked={workSessionIDs.includes(e.id)}
              />
              <Box>
                <Typography level="body2">{e.title}</Typography>
                <Typography level="body3">{e.formatDateRange()}</Typography>
              </Box>
            </ListItemButton>
          );
        })}
      </List>
      <Button
        startDecorator={<i className="fa-solid fa-person-from-portal"></i>}
        disabled={workSessionIDs.length === 0}
        onClick={releaseFromWorkSessionAction}
      >
        Excuse {releasableMusicians.length} musician
        {releasableMusicians.length > 1 ? "s" : ""}{" "}
        {workSessionIDs.length === workSessions.length
          ? "from Project"
          : `from ${workSessionIDs.length}  Work Session${
              workSessionIDs.length > 1 ? "s" : ""
            }`}
      </Button>
    </>
  );

  const releaseFromProjectPiece = (
    <>
      <Textarea
        startDecorator={
          <Typography
            endDecorator={<Typography level="body4">Required</Typography>}
          >
            Reason:
          </Typography>
        }
        autoFocus
        value={reason}
        onChange={(e) => setReason(e.target.value)}
        variant="soft"
        placeholder="Explanation of the release"
        endDecorator={
          <Typography level="body4">
            This message will appear in the musician's event history.
          </Typography>
        }
      />
      <Typography level="body2">
        Confirm the Project Piece(s) you wish to excuse the musician(s):
      </Typography>
      <List>
        {projectPieces.map((e) => {
          const piece = piecesMap[e.pieceID];
          return (
            <ListItemButton
              key={e.id}
              sx={{ gap: 1 }}
              onClick={() => {
                if (projectPieceIDs.includes(e.id)) {
                  setProjectPieceIDs((w) => w.filter((z) => z !== e.id));
                } else {
                  setProjectPieceIDs((w) => [...w, e.id]);
                }
              }}
            >
              <Checkbox checked={projectPieceIDs.includes(e.id)} />
              <Box>
                <Typography level="body2">{piece?.name}</Typography>
                <Typography level="body3">{piece?.composer}</Typography>
              </Box>
            </ListItemButton>
          );
        })}
      </List>
      <Button
        startDecorator={<i className="fa-solid fa-person-from-portal"></i>}
        disabled={projectPieceIDs.length === 0 || !reason}
        onClick={releaseFromProjectPieceAction}
      >
        Excuse {releasableMusicians.length} musician
        {releasableMusicians.length > 1 ? "s" : ""} from{" "}
        {projectPieceIDs.length} Project Piece
        {projectPieceIDs.length > 1 ? "s" : ""}
      </Button>
    </>
  );

  let content;

  if (!releaseFrom) content = releaseFromContent;
  if (releaseFrom === "Work Session(s)") content = releaseFromWorkSession;
  if (releaseFrom === "Project Piece(s)") content = releaseFromProjectPiece;

  return (
    <DialogClose open={open} onClose={onClose}>
      <DialogTitle>
        <Typography
          level="h6"
          startDecorator={<i className="fa-regular fa-calendar-xmark"></i>}
        >
          Excuse {releaseFrom ? "for " : ""}
          {releaseFrom ? (
            <Typography
              sx={{ pl: 1 }}
              color="primary"
              endDecorator={
                <IconButton
                  size="sm"
                  color="neutral"
                  variant="plain"
                  onClick={() => setReleaseFrom(undefined)}
                >
                  <i className="fa-solid fa-xmark"></i>
                </IconButton>
              }
            >
              {releaseFrom}
            </Typography>
          ) : (
            []
          )}
        </Typography>
        <Box sx={{ display: "flex", gap: 1 }}>
          {releasableMusicians.map((e) => (
            <Chip
              variant="soft"
              color="neutral"
              startDecorator={<MusicianAvatar musician={e} size={20} />}
              size="sm"
              key={e.id}
            >
              {e.fullName()}
            </Chip>
          ))}
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent
        sx={{
          gap: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {content}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button color="neutral" variant="soft" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </DialogClose>
  );
}
