/**
 * MusicianHoliday_Entity type guard.
 *
 * @param {any} musicianHolidayJson MusicianHoliday object from API
 * @returns {boolean} Return true if type is MusicianHoliday_Entity
 */
function validator(
  musicianHolidayJson: unknown
): musicianHolidayJson is MusicianHoliday_Entity {
  if (typeof musicianHolidayJson === "object" && musicianHolidayJson != null) {
    MusicianHoliday_Entity.requiredFields.forEach((element) => {
      if (!Object.keys(musicianHolidayJson).includes(element))
        throw new Error("Field ${element} is null or undefined");
    });
  }
  return true;
}

export default class MusicianHoliday_Entity {
  static requiredFields = [
    "id",
    "musicianID",
    "dateFromUTC",
    "dateToUTC",
    "kind",
    "comments",
    "createdAt",
    "updatedAt",
  ];

  constructor(musicianHolidayJson: unknown) {
    if (validator(musicianHolidayJson)) {
      this.id = musicianHolidayJson.id;
      this.musicianID = musicianHolidayJson.musicianID;
      this.dateFromUTC = musicianHolidayJson.dateFromUTC;
      this.dateToUTC = musicianHolidayJson.dateToUTC;
      this.kind = musicianHolidayJson.kind;
      this.comments = musicianHolidayJson.comments;
      this.createdAt = musicianHolidayJson.createdAt;
      this.updatedAt = musicianHolidayJson.updatedAt;
    } else {
      throw new Error(
        "Failed to create new instance of ${ MusicianHoliday_Entity.name }: ${e}"
      );
    }
  }

  id: number;
  musicianID: number;
  dateFromUTC: string;
  dateToUTC: string;
  kind: string;
  comments: string;
  createdAt: string;
  updatedAt: string;
}
