import { Add, Close } from "@mui/icons-material";
import Check from "@mui/icons-material/Check";
import {
  Alert,
  Button,
  IconButton,
  Input,
  Input as JoyTextField,
  List as JoyList,
  ListItem as JoyListItem,
  Sheet,
  CircularProgress,
  Checkbox,
  Chip,
  Select,
  Option,
  Typography,
} from "@mui/joy";
import {
  DialogContent,
  Hidden,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
} from "@mui/material";
import { Box } from "@mui/system";
import { useAskQuestion } from "features/context/AskQuestion/AskQuestion";
import SlidingDialog from "hooks/SlidingDialog";
import { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { useDispatch, useSelector } from "react-redux";
import { notifierBodySelector, setBody } from "reducers/notifier";
import {
  formOpenSelector,
  selectedProjectIDSelector,
  selectedProjectNoteIDSelector,
  setFormOpen,
  setNotifierBody,
  setSelectedProjectNoteID,
} from "reducers/rhapsody";
import { useCompanySettings } from "redux/company/companyHooks";
import { usePipeline } from "redux/pipeline/pipelineHooks";
import { useProject } from "redux/project/projectHooks";
import {
  useCreateProjectNoteMutation,
  useDeleteProjectNoteMutation,
  useUpdateProjectNoteMutation,
} from "redux/projectNote/projectNoteEndpoints";
import { useProjectNote } from "redux/projectNote/projectNoteHooks";
import { useSections } from "redux/section/sectionHooks";
import { useSectionRoles } from "redux/sectionRole/sectionRoleHooks";

export const COLORS = [
  "#000000",
  "#e60000",
  "#ff9900",
  "#ffff00",
  "#008a00",
  "#0066cc",
  "#9933ff",
  "#ffffff",
  "#facccc",
  "#ffebcc",
  "#ffffcc",
  "#cce8cc",
  "#cce0f5",
  "#ebd6ff",
  "#bbbbbb",
  "#f06666",
  "#ffc266",
  "#ffff66",
  "#66b966",
  "#66a3e0",
  "#c285ff",
  "#888888",
  "#a10000",
  "#b26b00",
  "#b2b200",
  "#006100",
  "#0047b2",
  "#6b24b2",
  "#444444",
  "#5c0000",
  "#663d00",
  "#666600",
  "#003700",
  "#002966",
  "#3d1466",
];

export default function FormProjectNote() {
  const projectID = useSelector(selectedProjectIDSelector);
  const { project } = useProject(projectID);
  const formOpen = useSelector(formOpenSelector("projectNote"));
  const selectedProjectNoteID = useSelector(selectedProjectNoteIDSelector);
  const [updateProjectNote] = useUpdateProjectNoteMutation();
  const [createProjectNote, { data }] = useCreateProjectNoteMutation();
  const [deleteProjectNote] = useDeleteProjectNoteMutation();
  const [loading, setLoading] = useState(false);
  const { projectNote: selectedProjectNote } = useProjectNote(
    selectedProjectNoteID
  );
  const notifierOpen = useSelector(formOpenSelector("notifier2"));
  // const notifierBody = useSelector(notifierBodySelector);
  const askQuestion = useAskQuestion();
  const { sections: instrumentSections } = useSections();
  const { sectionRoles } = useSectionRoles();
  const { pipeline } = usePipeline(project?.pipelineID ?? 1);
  const stages = (pipeline?.stages ?? [])?.filter((s) => s.manual);
  const dispatch = useDispatch();
  const body = useSelector(notifierBodySelector);
  const settings = useCompanySettings();

  const [projectNote, setProjectNote] = useState({
    value: "",
    autopilot: false,
    roles: "",
    sections: "",
    stages: "",
    name: "",
    include: true,
    projectID: projectID,
  });

  useEffect(() => {
    if (data?.id && body) {
      dispatch(
        setBody({ ...body, projectNoteIDs: [...body.projectNoteIDs, data.id] })
      );
    }
  }, [data]);

  useEffect(() => {
    if (selectedProjectNote?.id && !projectNote?.id) {
      setProjectNote(selectedProjectNote);
    }
  }, [selectedProjectNote]);

  const closeForm = () => {
    setProjectNote({
      value: "",
      autopilot: false,
      roles: "",
      sections: "",
      stages: "",
      name: "",
      projectID: projectID,
    });
    dispatch(setFormOpen({ isOpen: false, formID: "projectNote" }));
    dispatch(setSelectedProjectNoteID());
    setLoading(false);
  };

  const save = async () => {
    setLoading(true);
    if (selectedProjectNoteID) {
      updateProjectNote({
        id: selectedProjectNoteID,
        body: { ...projectNote },
      });
    } else {
      createProjectNote(projectNote)
        .unwrap()
        .then((e) => {
          // if (notifierOpen) {
          //   const _notifierBody = JSON.parse(JSON.stringify(notifierBody));
          //   _notifierBody.projectNoteIDs.push(e.id);
          //   dispatch(setNotifierBody(_notifierBody));
          // }
        });
    }
    setTimeout(closeForm, 1000);
  };

  const askDelete = () => {
    askQuestion("Are you sure?", ["Cancel", "Yes"], {
      subtitle: (
        <Typography>
          You are about to delete a Project Note, please confirm
        </Typography>
      ),
    }).then((i) => {
      if (i == 1) confirmDelete();
    });
  };

  const confirmDelete = () => {
    deleteProjectNote(selectedProjectNoteID);
    closeForm();
  };

  if (selectedProjectNoteID && !projectNote?.id) return <div />;

  const projectNotePreset = settings?.defaultProjectNotes?.find(
    (e) => e.content === projectNote.value
  );

  return (
    <SlidingDialog
      open={formOpen}
      onClose={closeForm}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogContent sx={{ p: 0 }}>
        <Box
          sx={{
            display: "flex",
            position: "relative",
            gap: 1,
            p: 0,
            height: `calc(100vh - 140px)`,
          }}
        >
          <Box
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              gap: 1,
              flex: 1,
            }}
          >
            <Select
              startDecorator={
                <Typography
                  level="body2"
                  startDecorator={
                    <Chip size="sm" color="info">
                      New
                    </Chip>
                  }
                >
                  <b>Use Snippet:</b>
                </Typography>
              }
              slotProps={{
                listbox: { sx: { zIndex: 9999 } },
              }}
              variant="soft"
              placeholder={
                projectNotePreset ? projectNotePreset.name : "Select a Snippet"
              }
              value={""}
              onChange={(e, v) => {
                if (v) {
                  setProjectNote((e) => ({
                    ...e,
                    name: v.name,
                    value: v.content,
                  }));
                }
              }}
            >
              {settings.defaultProjectNotes?.map((o) => (
                <Option key={o.content} value={o}>
                  {o.name}
                </Option>
              ))}
              <Option
                onClick={() =>
                  dispatch(
                    setFormOpen({
                      isOpen: true,
                      formID: "defaultProjectNotes",
                    })
                  )
                }
                value="create"
                color="primary"
              >
                <b>+ Create Snippet</b>
              </Option>
            </Select>
            <Input
              label="Note Name"
              autoFocus
              variant="soft"
              startDecorator="Name:"
              value={projectNote.name}
              onChange={(e) =>
                setProjectNote((s) => ({ ...s, name: e.target.value }))
              }
            />
            <ReactQuill
              theme="snow"
              style={{ height: "calc(100% - 100px)" }}
              value={projectNote.value}
              onChange={(n) => setProjectNote((e) => ({ ...e, value: n }))}
              formats={[
                "header",
                "font",
                "size",
                "bold",
                "italic",
                "underline",
                "strike",
                "blockquote",
                "list",
                "bullet",
                "indent",
                "link",
                "image",
                "video",
                "color",
                "background",
                "align",
                "direction",
                "code",
                "code-block",
              ]}
              modules={{
                toolbar: [
                  ["bold", "italic", "underline", "strike"], // toggled buttons
                  ["blockquote", "code-block"],

                  [{ header: 1 }, { header: 2 }], // custom button values
                  [{ list: "ordered" }, { list: "bullet" }],
                  [{ script: "sub" }, { script: "super" }], // superscript/subscript
                  [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
                  [{ direction: "rtl" }], // text direction

                  [{ size: ["small", false, "large", "huge"] }], // custom dropdown
                  [{ header: [1, 2, 3, 4, 5, 6, false] }],

                  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                  [{ font: [] }],
                  [{ align: [] }],

                  ["clean"], // remove formatting button
                ],
                clipboard: {
                  // toggle to add extra line breaks when pasting HTML:
                  matchVisual: false,
                },
              }}
            />
          </Box>
          <Hidden smDown>
            <Sheet
              variant="soft"
              sx={{
                width: 220,
                p: 2,
                display: "flex",
                flexDirection: "column",
                gap: 1,
                overflow: "auto",
              }}
            >
              <Checkbox
                checked={projectNote.include ?? false}
                label={"Auto Include in Emails"}
                onChange={(e) =>
                  setProjectNote((s) => ({ ...s, include: e.target.checked }))
                }
              />
              {/* <Button
                disabled={!projectNote.include}
                startDecorator={<i className="fa-duotone fa-robot"></i>}
                color={projectNote.autopilot ? "primary" : "neutral"}
                variant={projectNote.autopilot ? "solid" : "outlined"}
                fullWidth
                onClick={() =>
                  setProjectNote((s) => ({ ...s, autopilot: !s.autopilot }))
                }
              >
                Use in Autopilot
              </Button> */}
              {projectNote.include ? (
                <>
                  <RestrictTo
                    values={projectNote.roles}
                    collection={sectionRoles}
                    title="Restrict to Roles"
                    onChange={(e) =>
                      setProjectNote((s) => ({ ...s, roles: e }))
                    }
                  />
                  <RestrictTo
                    values={projectNote.sections}
                    collection={instrumentSections}
                    title="Restrict to Sections"
                    onChange={(e) =>
                      setProjectNote((s) => ({ ...s, sections: e }))
                    }
                  />
                  <RestrictTo
                    values={projectNote.stages}
                    collection={stages}
                    title="Restrict to Stages"
                    onChange={(e) =>
                      setProjectNote((s) => ({ ...s, stages: e }))
                    }
                  />
                  <JoyTextField
                    label="Priority"
                    type="tel"
                    placeholder="Priority (ex: 1)"
                    size="sm"
                    onChange={(e) =>
                      setProjectNote((s) => ({
                        ...s,
                        priority: parseInt(e.target.value),
                      }))
                    }
                    value={projectNote.priority}
                  />
                  <Alert size="sm" color={"neutral"}>
                    <div>
                      Multiple notes can be included. The order is based on the
                      Priority field (number).
                      <br />
                      <br />
                      <b>
                        <u>Example:</u>
                        <br />
                        0 is high priority
                        <br />
                        1 is less priority
                        <br />
                        ...etc
                      </b>
                    </div>
                  </Alert>
                </>
              ) : (
                []
              )}
            </Sheet>
          </Hidden>
        </Box>
        <Box
          sx={{
            position: "absolute",
            bottom: 30,
            p: 1,
            justifyContent: "space-between",
            flex: 1,
            display: "flex",
            width: "100%",
          }}
        >
          {selectedProjectNoteID ? (
            <Button variant="soft" color="danger" onClick={askDelete}>
              Delete
            </Button>
          ) : (
            <Box />
          )}
          <Box sx={{ gap: 1, display: "flex" }}>
            <Button variant="soft" color="neutral" onClick={closeForm}>
              Cancel
            </Button>
            <Button
              startDecorator={loading ? <CircularProgress /> : []}
              onClick={save}
            >
              Save
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </SlidingDialog>
  );
}

function RestrictTo({ values, collection, title, onChange, disabled }) {
  const [anchorEl, setAnchorEl] = useState(false);
  const valuesArray = [];
  values.split(",").forEach((s) => s && valuesArray.push(parseInt(s)));

  const handleClick = (id) => {
    const index = valuesArray.indexOf(id);
    if (index >= 0) {
      const _valuesArray = [...valuesArray];
      _valuesArray.splice(index, 1);
      onChange(_valuesArray.join(","));
    } else {
      onChange([...valuesArray, id].join(","));
    }
  };

  return (
    <Box>
      <Button
        disabled={disabled}
        onClick={(e) => setAnchorEl(e.currentTarget)}
        color="neutral"
        fullWidth
        variant="plain"
      >
        {title} <i class="fa-solid fa-chevron-down p-left"></i>
      </Button>
      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl()}
      >
        <List dense sx={{ borderRadius: "8px" }}>
          {collection.map((e) => (
            <ListItem
              key={e.id}
              button
              onClick={() => handleClick(e.id)}
              selected={valuesArray.indexOf(e.id) >= 0}
            >
              <ListItemAvatar>
                {valuesArray.indexOf(e.id) >= 0 ? <Check /> : <Add />}
              </ListItemAvatar>
              <ListItemText primary={e.name}></ListItemText>
            </ListItem>
          ))}
        </List>
      </Popover>
      {valuesArray.length ? (
        <JoyList size="sm" sx={{ borderRadius: "4px", overflow: "hidden" }}>
          {valuesArray.map((v) => (
            <JoyListItem
              variant="soft"
              color="primary"
              key={v}
              size="sm"
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              {collection.find((e) => e.id === v)?.name}
              <IconButton
                size="small"
                variant="plain"
                onClick={() => handleClick(v)}
              >
                <Close />
              </IconButton>
            </JoyListItem>
          ))}
        </JoyList>
      ) : (
        []
      )}
    </Box>
  );
}
