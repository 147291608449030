import { MusicianHoliday } from "entities/musicianHoliday";
import { useMemo } from "react";
import {
  useGetMusicianHolidayQuery,
  useGetMusicianHolidaysQuery,
} from "./musicianHolidayEndpoints";
import moment from "moment";

export function useMusicianHoliday(musicianHolidayID: number | undefined) {
  const musicianHolidayQuery = useGetMusicianHolidayQuery(
    musicianHolidayID ?? 0,
    {
      skip: (musicianHolidayID ?? 0) <= 0,
    }
  );
  const musicianHolidayEntity = musicianHolidayQuery.data;

  return useMemo(() => {
    const ret: typeof musicianHolidayQuery & {
      musicianHoliday?: MusicianHoliday | undefined;
    } = {
      ...musicianHolidayQuery,
    };
    if (musicianHolidayEntity)
      ret.musicianHoliday = new MusicianHoliday(musicianHolidayEntity);
    return ret;
  }, [musicianHolidayEntity]);
}

export function useMusicianHolidays(args?, opts?) {
  const musicianHolidayQuery = useGetMusicianHolidaysQuery(args, opts);
  const musicianHolidayEntitiesMap = musicianHolidayQuery.data?.entities;

  return useMemo(() => {
    const ret: typeof musicianHolidayQuery & {
      musicianHolidays?: MusicianHoliday[];
      musicianHolidaysMap?: { [id: number]: MusicianHoliday };
    } = {
      ...musicianHolidayQuery,
      musicianHolidays: [],
      musicianHolidaysMap: {},
    };
    if (musicianHolidayEntitiesMap) {
      const musicianHolidays: MusicianHoliday[] = [];
      const musicianHolidaysMap: { [id: number]: MusicianHoliday } = {};

      for (const key in musicianHolidayEntitiesMap) {
        if (
          Object.prototype.hasOwnProperty.call(musicianHolidayEntitiesMap, key)
        ) {
          const c = musicianHolidayEntitiesMap[key];
          const musicianHoliday = new MusicianHoliday(c);
          musicianHolidays.push(musicianHoliday);
          musicianHolidaysMap[musicianHoliday.id] = musicianHoliday;
        }
      }
      musicianHolidays.sort(
        (a, b) =>
          moment(a.dateFromUTC).valueOf() - moment(b.dateFromUTC).valueOf()
      );
      ret.musicianHolidays = musicianHolidays;
      ret.musicianHolidaysMap = musicianHolidaysMap;
    }

    return ret;
  }, [musicianHolidayEntitiesMap]);
}
