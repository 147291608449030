import { nerdNetworkBaseQuery } from "@nerdjs/nerd-network";
import { createApi } from "@reduxjs/toolkit/query/react";

export const rhapsodyApi = createApi({
  reducerPath: "rhapsodyApi",
  baseQuery: (args, api) =>
    nerdNetworkBaseQuery(args, api, { service: "rhapsody" }),
  endpoints: () => ({}),
  tagTypes: [
    "musicians",
    "noteTypes",
    "musicianInstruments",
    "musicianCommunications",
    "customers",
    "venues",
    "payrolls",
    "pieces",
    "linkTies",
    "projectMusicians",
    "projectAlternates",
    "companies",
    "workSessions",
    "sectionRoles",
    "workSessionSections",
    "workSessionTypes",
    "jobs",
    "seasonMusicians",
    "projects",
    "projectSections",
    "musicianHolidays",
    "hiringNotify",
    "projectNotes",
    "companies",
    "projectHirings",
    "links",
    "mercuryJobs",
    "dumb",
    "groups",
    "tags",
    "projectTags",
    "layouts",
    "ties",
    "musicianNotes",
    "musicianGroups",
    "musicianSuggestions",
    "workSessionMercury",
    "projectMercury",
    "subscriptions",
    "projectPieces",
    "projectPieceSections",
    "projectPieceChairs",
    "workSessionProjectPieces",
    "musiciansForProjectSection",
    "musiciansForWorkSessionSection",
    "digests",
    "assignments",
    "missions",
    "eventStatuses",
    "chairs",
    "autopilot",
    "events",
    "subRules",
    "projectContacts",
    "emails",
    "scales",
    "seasonMercury",
    "projectRosters",
  ],
});
