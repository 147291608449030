import MusicianHoliday_Entity from "./musicianHoliday";
export default class MusicianHoliday extends MusicianHoliday_Entity {
  static fromList(musicianHolidaysJSON: unknown): Array<MusicianHoliday> {
    const musicianHolidays: MusicianHoliday[] = [];
    if (musicianHolidaysJSON)
      Array.isArray(musicianHolidaysJSON) &&
        musicianHolidaysJSON.forEach((musicianHolidayJSON) => {
          musicianHolidays.push(new MusicianHoliday(musicianHolidayJSON));
        });
    return musicianHolidays;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
