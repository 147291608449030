import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { MusicianHoliday_Entity } from "entities/musicianHoliday";
import { rhapsodyApi } from "redux/api";

const musicianHolidaysAdapter = createEntityAdapter<MusicianHoliday_Entity>();
const initialState = musicianHolidaysAdapter.getInitialState();

export const musicianHolidayEndpoints = rhapsodyApi.injectEndpoints({
  endpoints: (build) => ({
    getMusicianHolidays: build.query<
      EntityState<MusicianHoliday_Entity>,
      Record<string, unknown> | void
    >({
      query: (args) => {
        return {
          url: `musicianHolidays`,
          params: args,
        };
      },
      transformResponse: (responseData: MusicianHoliday_Entity[]) => {
        return musicianHolidaysAdapter.setAll(initialState, responseData);
      },
      providesTags: ["musicianHolidays"],
    }),
    getMusicianHoliday: build.query<MusicianHoliday_Entity, number>({
      query: (id) => `musicianHolidays/${id}`,
      providesTags: (result, error, id) => [{ type: "musicianHolidays", id }],
    }),
    createMusicianHoliday: build.mutation<
      MusicianHoliday_Entity,
      Partial<MusicianHoliday_Entity>
    >({
      query: (body) => ({
        method: "POST",
        body,
        url: `musicianHolidays`,
      }),
      invalidatesTags: ["musicianHolidays"],
    }),
    updateMusicianHoliday: build.mutation<
      void,
      { id: number; body: Partial<MusicianHoliday_Entity> }
    >({
      query: (args) => ({
        method: "PUT",
        body: args.body,
        url: `musicianHolidays/${args.id}`,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "musicianHolidays", id },
        "musicianHolidays",
      ],
    }),
    deleteMusicianHoliday: build.mutation<void, number>({
      query: (id) => ({
        method: "DELETE",
        url: `musicianHolidays/${id}`,
      }),
      invalidatesTags: ["musicianHolidays"],
    }),
  }),
});

export const {
  useGetMusicianHolidayQuery,
  useGetMusicianHolidaysQuery,
  useCreateMusicianHolidayMutation,
  useDeleteMusicianHolidayMutation,
  useUpdateMusicianHolidayMutation,
} = musicianHolidayEndpoints;

export default musicianHolidayEndpoints;
