import { Box, Button, Divider, Typography } from "@mui/joy";
import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import DialogClose from "atoms/DialogClose/DialogClose";
import { useAskQuestion } from "features/context/AskQuestion/AskQuestion";
import MusicianAvatar from "features/musicians/MusicianAvatar";
import { mapToArray } from "helpers";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  formOpenSelector,
  notifierBodySelector,
  selectedProjectIDSelector,
  setFormOpen,
  setNotifier2,
  setNotifierBody,
} from "reducers/rhapsody";
import {
  assignmentIDForReleaseSelector,
  layoutInternalSelector,
  layoutUtilsSelector,
  selectPositions,
  selectionSelector,
  setAssignmentIDForRelease,
} from "reducers/v2/missionControl";
import { useUpdateAssignmentMutation } from "redux/assignment/assignmentEndpoints";
import { useLazyGetUpcomingStagesQuery } from "redux/projectMercury/projectMercuryEndpoints";
import {
  useCreateChairsMutation,
  useUpdateChairMutation,
} from "redux/rhapsodyChair/rhapsodyChairEndpoints";

/**
 *
 * @returns {ReactElement} ReleaseRequest page
 */
export function ReleaseRequest() {
  const open = useSelector(formOpenSelector("releaseRequests"));
  const internal = useSelector(layoutInternalSelector);
  const { releaseRequests } = internal;
  const askQuestion = useAskQuestion();
  const selection = useSelector(selectionSelector);
  const projectID = useSelector(selectedProjectIDSelector);
  const selectedAssignmentID = useSelector(assignmentIDForReleaseSelector);
  const body = useSelector(notifierBodySelector);
  const utils = useSelector(layoutUtilsSelector);
  const { assignmentsMap, musiciansMap, workSessionsMap, chairs } = utils;
  const assignments = mapToArray(assignmentsMap);
  const [updateChair] = useUpdateChairMutation();
  const [createChairs] = useCreateChairsMutation();
  const [updateAssignment] = useUpdateAssignmentMutation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (releaseRequests?.length === 0) {
      onClose();
    }
  }, [releaseRequests]);
  const [getUpcomingStages] = useLazyGetUpcomingStagesQuery();

  const onClose = () => {
    dispatch(setAssignmentIDForRelease());
    dispatch(setFormOpen({ isOpen: false, formID: "releaseRequests" }));
  };

  const sendMessage = async (
    musicianID: number,
    greeting: string,
    selectedStageID: number
  ) => {
    const { data } = await getUpcomingStages({
      id: projectID,
      body: {
        musicianIDs: [musicianID],
      },
    });
    const assignment = assignments.find((a) => a.musicianID === musicianID);
    const positionIDs = internal.getAssignmentPositionIDs(
      assignment.id,
      utils,
      selection
    );
    dispatch(selectPositions(positionIDs));
    dispatch(setNotifierBody({ ...body, greeting }));
    dispatch(setNotifier2({ selectedStageID, hProject: data }));
    dispatch(setFormOpen({ formID: "notifier2", isOpen: true }));
  };

  const clearReleaseRequest = async (assignmentID: number) => {
    await updateAssignment({
      id: assignmentID,
      body: {
        releaseExplanation: "",
        releaseWorkSessionIDs: "",
        importantChanges: true,
      },
    }).unwrap();
  };

  const removeMusician = async (
    assignmentID: number,
    workSessionIDs: number[]
  ) => {
    for (const k in workSessionIDs) {
      if (Object.prototype.hasOwnProperty.call(workSessionIDs, k)) {
        const workSessionID = workSessionIDs[k];

        const assignmentChairs = chairs.filter((c) => {
          return (
            c.assignmentID === assignmentID &&
            c.workSessionIDs.includes(workSessionID)
          );
        });

        for (const j in assignmentChairs) {
          if (Object.prototype.hasOwnProperty.call(assignmentChairs, j)) {
            const c = assignmentChairs[j];
            if (c.workSessionID) {
              await updateChair({
                id: c.id,
                body: { ...c, musicianID: -1 },
              }).unwrap();
            } else {
              const body = { ...c };
              body.chairCount = 1;
              delete body.id;
              delete body.createdAt;
              delete body.updatedAt;
              delete body.assignmentID;
              body.musicianID = -1;
              delete body.workSessionIDs;
              delete body.workSessionID;
              body.workSessionID = workSessionID;
              await createChairs([body]).unwrap();
            }
          }
        }
      }
    }
  };

  const accept = async (assignmentID: number, workSessionIDs: number[]) => {
    const assignment = assignmentsMap[assignmentID];
    await updateAssignment({
      id: assignment.id,
      body: { importantChanges: true },
    }).unwrap();
    const allWorkSessionIDs = chairs.reduce((a, v) => {
      if (v.assignmentID === assignmentID) {
        v.workSessionIDs.forEach((w) => {
          if (!a.includes(w)) a.push(w);
        });
      }
      return a;
    }, []);
    const isRelease = allWorkSessionIDs.reduce((a, v) => {
      if (!workSessionIDs.includes(v)) a = false;
      return a;
    }, true);
    const musician = musiciansMap[assignment.musicianID];
    if (!isRelease) {
      // await clearReleaseRequest(assignment.id);
      await removeMusician(assignmentID, workSessionIDs);
    }

    sendMessage(
      musician.id,
      "",
      (isRelease ? utils.releaseStage?.id : utils.revisionStage?.id) ??
        utils.messageStage?.id
    );

    if (selectedAssignmentID) onClose();
  };

  const reject = (assignmentID: number) => {
    const assignment = assignmentsMap[assignmentID];
    const musician = musiciansMap[assignment.musicianID];
    askQuestion("Reject: Send a message?", ["No", "Yes"], {
      subtitle: (
        <Typography>
          {musician.fullName()} won't be removed from the session(s).
          <br />
          Would you like to send a message?
        </Typography>
      ),
    }).then(async (i) => {
      await clearReleaseRequest(assignment.id);
      if (i) sendMessage(musician.id, "", utils.messageStage?.id);
      if (selectedAssignmentID) onClose();
    });
  };

  return (
    <DialogClose open={open} onClose={onClose}>
      <DialogTitle>
        <Typography
          level="h6"
          startDecorator={<i className="fa-solid fa-person-from-portal"></i>}
        >
          Release Requests
        </Typography>
        <Typography level="body2">
          Review the {releaseRequests.length} Release Request
          {releaseRequests.length > 1 ? "s" : ""}
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContent
        sx={{
          gap: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {releaseRequests.map((a) => {
          const musician = musiciansMap[a.musicianID];
          const workSessionIDs = a.releaseWorkSessionIDs
            ? JSON.parse(a.releaseWorkSessionIDs)
            : [];
          return (
            <Box key={a.id} sx={{ display: "flex", gap: 1, flex: 1 }}>
              <MusicianAvatar musician={musician} />
              <Divider orientation="vertical" />
              <Box sx={{ flexGrow: 1 }}>
                <Box sx={{ display: "flex" }}>
                  <Typography
                    sx={{
                      background: "#E9E9EB",
                      p: 0.5,
                      borderRadius: "8px",
                      color: "black",
                    }}
                    level="body2"
                  >
                    {a.releaseExplanation}
                  </Typography>
                </Box>
                <Typography level="body2">
                  {musician.fullName()} requests to be released from:
                </Typography>
                <ul>
                  {workSessionIDs.map((wID) => (
                    <li key={wID}>
                      <Typography>{workSessionsMap[wID].title}</Typography>
                      <Typography level="body2">
                        {workSessionsMap[wID].formatDateRange()}
                      </Typography>
                    </li>
                  ))}
                </ul>
                <Box sx={{ display: "flex", gap: 1 }}>
                  <Button
                    startDecorator={<i className="fa-solid fa-thumbs-down"></i>}
                    sx={{ flex: 1 }}
                    variant="soft"
                    color="danger"
                    onClick={() => reject(a.id)}
                  >
                    Reject
                  </Button>
                  <Button
                    startDecorator={<i className="fa-solid fa-thumbs-up"></i>}
                    sx={{ flex: 1 }}
                    color="success"
                    variant="soft"
                    onClick={() => accept(a.id, workSessionIDs)}
                  >
                    Accept
                  </Button>
                </Box>
              </Box>
            </Box>
          );
        })}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button color="neutral" variant="soft" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </DialogClose>
  );
}
