import { Switch, Tooltip, Typography, useColorScheme } from "@mui/joy";
import { Button, FormControlLabel, Hidden } from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { User } from "@nerdjs/account-kit";
import Apps from "components/Apps";
import { REACT_APP_FRONT_BASE, REACT_APP_LOGO } from "config";
import CreateCenter from "hooks/CreateCenter";
import { IntercomButton } from "hooks/intercom/intercomButton";
import { IntercomConfig } from "hooks/intercom/intercomConfig";
import MyAccount from "hooks/MyAccount";
import { Subscription } from "hooks/subscription/subscription";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  layoutDebugSelector,
  layoutSetDebug,
} from "reducers/v2/missionControl";
import { appSelector } from "selectors/app";
import { meSelector } from "selectors/me";
import { userAppsSelector } from "selectors/userApp";

/**
 *
 * @returns {ReactElement} RhapsodyAppBar page
 */
export function RhapsodyAppBar() {
  const dispatch = useDispatch();
  const userApps = useSelector(userAppsSelector);
  const app = useSelector(appSelector);
  const debug = useSelector(layoutDebugSelector);
  const me: User = useSelector(meSelector);
  const superadmin = me?.services?.account?.indexOf("superadmin") >= 0;
  const scheme = useColorScheme();

  useEffect(() => {
    scheme.setMode("light");
  }, []);

  return (
    <AppBar position="relative">
      <IntercomConfig />
      <IntercomButton />
      <Box
        sx={{
          maxWidth: 1600,
          width: "100%",
          margin: "auto",
          display: "flex",
          gap: 1,
          flex: 1,
          alignItems: "center",
          justifyContent: "start",
          pl: 1,
          pr: 1,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Button size="small" component={Link} to="/">
            <Hidden smDown>
              <img alt="logo" src={REACT_APP_LOGO} height="30" />
            </Hidden>
            <Hidden smUp>
              <img
                alt="logo"
                src={
                  "https://storage.googleapis.com/rhapsody/logos/rhapsody-condensed-white.png"
                }
                height="30"
              />
            </Hidden>
          </Button>
          <Hidden mdDown>
            <Subscription />
          </Hidden>
        </Box>

        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ display: "flex", gap: 1 }}>
          {superadmin ? (
            <Tooltip
              title="Only super admins see this"
              arrow
              size="sm"
              variant="outlined"
              enterDelay={1000}
              enterNextDelay={1000}
            >
              <FormControlLabel
                control={
                  <Switch
                    size="sm"
                    checked={debug ?? false}
                    onChange={(e) => dispatch(layoutSetDebug(e.target.checked))}
                  />
                }
                label={
                  <Typography level="body3" sx={{ color: "white", ml: 1 }}>
                    Debug Mode
                  </Typography>
                }
              />
            </Tooltip>
          ) : (
            []
          )}

          <CreateCenter />
        </Box>
        <Apps
          userApps={userApps}
          app={app}
          baseHostname={REACT_APP_FRONT_BASE}
        />
        <MyAccount />
      </Box>
    </AppBar>
  );
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme }) => ({
  boxShadow: "none",
  background: "#021C33",
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));
