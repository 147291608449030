import {
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Sheet,
  Tooltip,
  useTheme,
} from "@mui/joy";
import JoyTypography from "@mui/joy/Typography";
import { Box, Menu, MenuItem, Typography, useMediaQuery } from "@mui/material";
import {
  DataGridPro,
  GridToolbarQuickFilter,
  useGridApiContext,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import Id from "atoms/Id/Id";
import { Musician } from "entities/musician";
import { useAskQuestion } from "features/context/AskQuestion/AskQuestion";
import MusicianAvatar from "features/musicians/MusicianAvatar";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedMusicianID } from "reducers/rhapsody";
import { rhapsodyApi } from "redux/api";
import { useGroups } from "redux/group/groupHooks";
import {
  useDeleteMusicianMutation,
  useUpdateMusician2Mutation,
  useUpdateMusicianMutation,
} from "redux/musician/musicianEndpoints";
import {
  useMusicians,
  useMusiciansForGroup,
} from "redux/musician/musicianHooks";
import {
  useCreateMusicianGroupMutation,
  useDeleteMusicianGroupMutation,
} from "redux/musicianGroup/musicianGroupEndpoints";
import { useMusicianHolidays } from "redux/musicianHoliday/musicianHolidayHooks";
import { meSelector } from "selectors/me";

export default function MusiciansDataGrid({ groupID, autoheight = true }) {
  const [checkboxSelection, setCheckboxSelection] = useState(false);
  const [selected, setSelected] = useState([]);
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const [updateMusician] = useUpdateMusician2Mutation();
  const askQuestion = useAskQuestion();
  const [loading, setLoading] = useState(false);
  const me = useSelector(meSelector);
  const superadmin = me?.services?.account?.indexOf("superadmin") >= 0;

  const {
    musicians,
    isLoading: l1,
    isFetching: f1,
  } = useMusicians(undefined, { skip: groupID > 0 });
  const {
    musicians: groupMusicians,
    isLoading: l2,
    isFetching: f2,
  } = useMusiciansForGroup(groupID, groupID <= 0);

  let rows = musicians;
  if (groupID) rows = groupMusicians;

  if (groupID === -1) rows = musicians;
  if (groupID === 0) rows = musicians.filter((m) => m.sub);

  const isLoading = l1 || l2 || f1 || f2;
  const { musicianHolidays } = useMusicianHolidays();
  const [pageSize, setPageSize] = useState(20);
  const dispatch = useDispatch();
  const apiRef = useGridApiRef();

  useEffect(() => {
    if (!checkboxSelection && apiRef?.current) {
      setSelected([]);
      apiRef.current.selectRows(selected, false);
    }
  }, [checkboxSelection]);

  const batchUpate = async (body: Partial<Musician>) => {
    const proms = [];
    setLoading(true);
    selected.forEach((musicianID) => {
      const match = rows.find((m) => m.id === musicianID);
      if (match) {
        proms.push(
          updateMusician({
            id: musicianID,
            body: { ...match, ...body },
          }).unwrap()
        );
      }
    });

    await Promise.all(proms);
    setLoading(false);
    askQuestion("Done", ["OK"], {
      subtitle: <Typography>Selected musicians have been updated.</Typography>,
    });
    dispatch(rhapsodyApi.util.invalidateTags(["musicians"]));
  };

  return (
    <>
      <DataGridPro
        autoHeight={autoheight}
        density="compact"
        loading={isLoading}
        apiRef={apiRef}
        onRowClick={(p) => dispatch(setSelectedMusicianID(p.row.id))}
        rows={rows}
        rowHeight={60}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        pagination
        onSelectionModelChange={(e) => {
          setSelected(e);
        }}
        getCellClassName={(p) => (!p.row.active ? "archived" : "")}
        checkboxSelection
        pageSize={pageSize}
        columns={[
          {
            field: "id",
            headerName: "ID",
            align: "left",
            type: "number",
            width: 75,
            renderCell: (p) => (
              <Id startDecorator={<i className="fa-solid fa-user-music"></i>}>
                {p.row.id}
              </Id>
            ),
          },
          // {
          //   field: "star",
          //   headerName: "Favorite",
          //   type: "boolean",
          //   width: 75,
          //   renderCell: (p) =>
          //     p.row.star ? <Favorite sx={{ color: "#e91e63" }} /> : [],
          // },
          {
            field: "avatar",
            headerName: "",
            type: "string",
            width: 90,
            renderCell: (p) => (
              <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                <MusicianAvatar noBadge size={32} musician={p.row} />
                {p.row.isOff(musicianHolidays) ? (
                  <Tooltip
                    title={`Is currently off until ${p.row.offUntil(
                      musicianHolidays
                    )} included`}
                    size="sm"
                    arrow
                    variant="outlined"
                    enterDelay={1000}
                    enterNextDelay={1000}
                  >
                    <i className="fa-solid fa-island-tropical"></i>
                  </Tooltip>
                ) : (
                  []
                )}
                {p.row.userUUID ? (
                  <Tooltip
                    title="Mobile app user"
                    size="sm"
                    arrow
                    variant="outlined"
                    enterDelay={1000}
                    enterNextDelay={1000}
                  >
                    <i className="fa-regular fa-mobile-notch"></i>
                  </Tooltip>
                ) : (
                  []
                )}
              </Box>
            ),
          },
          {
            field: "firstName",
            headerName: "First Name",
            type: "string",
            width: 100,
          },
          {
            field: "lastName",
            headerName: "Last Name",
            type: "string",
            width: 100,
          },
          {
            field: "comments",
            headerName: "Internal Memo",
            type: "string",
            width: 150,
            renderCell: (p) => (
              <Sheet
                sx={{ background: "rgba(255,235,59,0.1)", p: p.value && 1 }}
              >
                <JoyTypography level="body3">{p.value}</JoyTypography>
              </Sheet>
            ),
          },
          {
            field: "companyName",
            headerName: "Company Name",
            type: "string",
            width: 130,
          },
          {
            field: "instruments",
            headerName: "Instruments",
            type: "string",
            width: 200,
            valueGetter: (p) => getInstrumentsString(p.row.instruments),
          },
          {
            field: "phone",
            headerName: "Phone",
            type: "string",
            width: 150,
          },
          {
            field: "email",
            headerName: "Email",
            type: "string",
            width: 200,
          },
          {
            field: "city",
            headerName: "City",
            type: "string",
            width: 100,
          },
          {
            field: "birthdate",
            headerName: "Birthdate",
            type: "string",
            width: 250,
            valueFormatter: (v) => {
              const date = moment(v.value).utc();
              return v.value && v.value !== "0001-01-01T00:00:00Z"
                ? `${date.format("MM/DD/YYYY")} ${
                    date.format("MM/DD") === moment().format("MM/DD")
                      ? "🥳"
                      : ""
                  }`
                : "";
            },
          },
          {
            field: "archived",
            headerName: "Active",
            type: "boolean",
            width: 100,
            valueGetter: (r) => !r.row.active,
          },
          {
            field: "archived2",
            headerName: "Archived",
            type: "string",
            width: 30,
            valueGetter: (r) => (!r.row.active ? "archived" : ""),
          },
        ]}
        disableSelectionOnClick
        components={{
          Toolbar: CustomToolbar,
        }}
        columnVisibilityModel={{
          id: !sm,
          comments: !sm,
          companyName: !sm,
          instruments: !sm,
          star: !sm,
          phone: !sm,
          email: !sm,
          city: !sm,
          archived2: false,
        }}
        componentsProps={{
          toolbar: {
            setCheckboxSelection,
            selected,
            groupID,
            rows,
          },
        }}
        initialState={{
          sorting: {
            sortModel: [
              {
                field: "lastName",
                sort: "asc",
              },
            ],
          },
        }}
      />
      {selected.length > 0 ? (
        <Box sx={{ width: 320, p: 1 }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box>
              <Typography variant="h6">Bulk Actions</Typography>
              <Typography variant="body2">
                {selected.length} Musicians
              </Typography>
            </Box>
            {loading ? <CircularProgress size="sm" /> : []}
          </Box>
          <Box sx={{ display: "flex", gap: 1, flexDirection: "column", mt: 2 }}>
            <Button
              onClick={() => batchUpate({ star: true })}
              size="sm"
              variant="soft"
              color="neutral"
            >
              Add to Favorite
            </Button>
            <Button
              onClick={() => batchUpate({ star: false })}
              size="sm"
              variant="soft"
              color="neutral"
            >
              Remove from Favorite
            </Button>
            <Box />
            <Box />
            <Button
              onClick={() => batchUpate({ sub: true })}
              size="sm"
              variant="soft"
              color="neutral"
            >
              Set as Sub
            </Button>
            <Button
              onClick={() => batchUpate({ sub: false })}
              size="sm"
              variant="soft"
              color="neutral"
            >
              Remove from Sub
            </Button>
            <Box />
            <Box />
            <Button
              onClick={() => batchUpate({ contactBySMS: true })}
              size="sm"
              variant="soft"
              color="neutral"
            >
              Enable SMS
            </Button>
            <Button
              onClick={() => batchUpate({ contactBySMS: false })}
              size="sm"
              variant="soft"
              color="neutral"
            >
              Disable SMS
            </Button>
            <Box />
            <Box />
            <Button
              onClick={() => batchUpate({ active: false })}
              size="sm"
              variant="soft"
              color="neutral"
            >
              Archive
            </Button>
            <Button
              onClick={() => batchUpate({ active: true })}
              size="sm"
              variant="soft"
              color="neutral"
            >
              Unarchive
            </Button>
            {superadmin ? (
              <>
                <Box />
                <Divider>Only SuperAdmins can see the action bellow</Divider>
                <Box />
                <Button
                  onClick={() => batchUpate({ phone: "" })}
                  size="sm"
                  variant="soft"
                  color="info"
                >
                  Remove Phone
                </Button>
              </>
            ) : (
              []
            )}
          </Box>
        </Box>
      ) : (
        []
      )}
    </>
  );
}

function CustomToolbar({ setCheckboxSelection, selected, groupID, rows }) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>();
  const { groups } = useGroups();
  const { musiciansMap } = useMusiciansForGroup(groupID, groupID === undefined);
  const askQuestion = useAskQuestion();
  const [createMusicianGroup] = useCreateMusicianGroupMutation();
  const [deleteMusicianGroup] = useDeleteMusicianGroupMutation();
  const [updateMusician] = useUpdateMusicianMutation();
  const [deleteMusician] = useDeleteMusicianMutation();

  const me = useSelector(meSelector);
  const superadmin = me?.services?.account?.indexOf("superadmin") >= 0;

  const apiRef = useGridApiContext();

  const handleSetSub = async (sub: boolean) => {
    setAnchorEl(null);
    selected.forEach((musicianID) => {
      const match = rows.find((m) => m.id === musicianID);
      if (match) updateMusician({ id: musicianID, body: { ...match, sub } });
    });
    askQuestion("Success", ["OK"], {
      subtitle: (
        <Typography>
          {sub
            ? "Selected Musicians have been set as sub."
            : "Selected Musicians have been removed from the subs list."}
        </Typography>
      ),
    });
  };

  const handleCreateProjectGroup = async (gID) => {
    setAnchorEl(null);

    for (const key in selected) {
      if (Object.hasOwnProperty.call(selected, key)) {
        const musicianID = selected[key];
        createMusicianGroup({
          musicianID,
          groupID: gID,
        });
      }
    }
    askQuestion("Success", ["OK"], {
      subtitle: (
        <Typography>
          Selected Musicians have been added to the Group.
        </Typography>
      ),
    });

    apiRef.current.selectRows(selected, false);
    setCheckboxSelection(false);
  };

  const handleDeleteMusicianGroup = async () => {
    for (const key in selected) {
      if (Object.hasOwnProperty.call(selected, key)) {
        const musicianID = selected[key];
        deleteMusicianGroup({
          musicianID: musiciansMap[musicianID].id,
          id: musiciansMap[musicianID].groupRelationID,
        });
      }
    }
    apiRef.current.selectRows(selected, false);
    setCheckboxSelection(false);
  };

  const handleMassDelete = () => {
    askQuestion("Are you sure?", ["Cancel", "Yes"], {
      subtitle: (
        <Typography>You are about to delete selected musicians.</Typography>
      ),
    }).then((i) => {
      if (i == 1) {
        selected.forEach((musicianID) => {
          deleteMusician(musicianID);
        });
      }
    });
  };

  return (
    <Box
      className="header"
      sx={{
        display: "flex",
        justifyContent: "space-between",
        gap: 1,
        p: 1,
        alignItems: "center",
      }}
    >
      <GridToolbarQuickFilter
        size="small"
        fullWidth
        sx={{ flexGrow: 1 }}
        variant="outlined"
      />
      <Box sx={{ display: "flex", gap: 1, alignItems: "center", m: 1 }}>
        {selected?.length && groupID >= 0 ? (
          <Button
            size="sm"
            variant="outlined"
            onClick={
              groupID === 0
                ? () => handleSetSub(false)
                : handleDeleteMusicianGroup
            }
          >
            Remove {selected.length} Musician{selected.length > 1 ? "s" : ""}{" "}
            from {groupID === 0 ? "Subs" : "Group"}
          </Button>
        ) : (
          []
        )}
        {selected?.length && groups.length ? (
          <Button
            size="sm"
            variant="outlined"
            onClick={(e) => setAnchorEl(e.currentTarget)}
          >
            Add {selected.length} Musician{selected.length > 1 ? "s" : ""} to
            <i className="fa-solid fa-angle-down p-left"></i>
          </Button>
        ) : (
          []
        )}
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          sx={{ display: "flex", gap: 1 }}
          onClick={() => handleSetSub(true)}
        >
          Subs
        </MenuItem>
        {groups.map((t) => (
          <MenuItem
            key={t.id}
            sx={{ display: "flex", gap: 1 }}
            onClick={() => handleCreateProjectGroup(t.id)}
          >
            {t.image ? <img alt="image" src={t.image} height={30} /> : []}
            {t.name}
          </MenuItem>
        ))}
      </Menu>
      {superadmin && selected?.length ? (
        <IconButton onClick={handleMassDelete} size="sm" color="danger">
          <i className="fa-solid fa-trash"></i>
        </IconButton>
      ) : (
        []
      )}
    </Box>
  );
}

const getInstrumentsString = (instruments) => {
  const instrumentNames = [];
  for (const key in instruments) {
    if (Object.hasOwnProperty.call(instruments, key)) {
      const instrument = instruments[key];
      instrumentNames.push(instrument.name);
    }
  }
  return instrumentNames.join(", ");
};
