import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import { Button, IconButton, Tab, TabList, Tabs, Typography } from "@mui/joy";
import { alpha, Box } from "@mui/material";
import { RouterConfig } from "hooks/AppRouter/AppRouter";
import { useReadOnly } from "hooks/useReadOnly/useReadOnly";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setFormOpen,
  setSelectedMusicianHolidayID,
  setSelectedWorkSessionID,
} from "reducers/rhapsody";
import {
  setRescheduleEvent,
  setTmpWorkSession,
  setWorkSessionMode,
  tempWorkSessionSelector,
} from "reducers/workSessions";
import { useCompanySettings } from "redux/company/companyHooks";
import { useMusicianHolidays } from "redux/musicianHoliday/musicianHolidayHooks";
import {
  useActiveWorkSessions,
  useCustomerWorkSessions,
  useMusicianWorkSessions,
  usePayrollWorkSessions,
  useProjectWorkSessions,
  useVenueWorkSessions,
} from "redux/workSession/workSessionHooks";
import { calendarOptionsSelector } from "selectors/app";
export default function Calendar({
  projectID,
  musicianID,
  venueID,
  customerID,
  payrollID,
  tagID,
  pieceID,
  today,
}: {
  projectID?: number;
  musicianID?: number;
  venueID?: number;
  tagID?: number;
  customerID?: number;
  payrollID?: number;
  pieceID?: number;
  today?: boolean;
}) {
  const readOnly = useReadOnly();
  const dispatch = useDispatch();
  const calendarRef = useRef();
  const calendarOptions = useSelector(calendarOptionsSelector);
  const tmpWorkSession = useSelector(tempWorkSessionSelector(0));
  const settings = useCompanySettings();
  const [selectedDate, setSelectedDate] = useState("");
  const { activeWorkSessions, isFetching: l1 } = useActiveWorkSessions({
    skip: customerID > 0 || payrollID > 0 || projectID > 0 || musicianID > 0,
  });
  const navigate = useNavigate();
  const { customerWorkSessions, isFetching: l3 } =
    useCustomerWorkSessions(customerID);
  const { payrollWorkSessions, isFetching: l4 } =
    usePayrollWorkSessions(payrollID);
  const { projectWorkSessions, isFetching: l5 } =
    useProjectWorkSessions(projectID);
  const { musicianWorkSessions, isFetching: l6 } =
    useMusicianWorkSessions(musicianID);
  const { venueWorkSessions, isFetching: l7 } = useVenueWorkSessions(venueID);

  const { musicianHolidays } = useMusicianHolidays(
    {
      filters: JSON.stringify([
        {
          name: "musician_holidays.musicianID",
          value: musicianID,
          comparison: "eq",
        },
      ]),
    },
    { skip: !musicianID }
  );

  let workSessions = activeWorkSessions;
  if (customerID) workSessions = customerWorkSessions;
  if (payrollID) workSessions = payrollWorkSessions;
  if (projectID) workSessions = projectWorkSessions;
  if (musicianID) workSessions = musicianWorkSessions;
  if (venueID) workSessions = venueWorkSessions;

  if (!workSessions) workSessions = [];

  useEffect(() => {
    setSelectedDate(getSelectedDate());
  }, [workSessions]);

  const calendarEl: any = calendarRef?.current;
  const tzName = moment.tz.guess();

  const getEventsFromWorkSession = () => {
    const events = [];
    for (const key in workSessions) {
      if (Object.hasOwnProperty.call(workSessions, key)) {
        const workSession = workSessions[key];
        events.push({
          id: workSession.id,
          title: `${workSession.title}. ${workSession.studioName}. ${
            tzName !== workSession.tzName
              ? `\r\nLocal Time: ${workSession.formatDateRange()}`
              : ``
          }`,
          start: workSession.dateFromUTC,
          end: workSession.dateToUTC,
          color: workSession.projectColor,
          allDay: false,
          editable: projectID ? true : false,
          eventStartEditable: projectID ? true : false,
        });
      }
    }

    if (musicianID) {
      musicianHolidays.forEach((h) => {
        events.push({
          id: `time_off${h.id}`,
          title: `${h.kind}`,
          start: h.dateFromUTC,
          end: h.dateToUTC,
          color: "#f44336",
          allDay: true,
          editable: false,
          eventStartEditable: false,
        });
      });
    }

    console.log(events);
    return events;
  };

  const handleDateSelect = (selectInfo) => {
    if (readOnly) return;
    if (selectInfo.view.type !== "dayGridMonth") {
      dispatch(
        setTmpWorkSession({
          workSession: {
            ...tmpWorkSession,
            dateFromUTC: moment(selectInfo.startStr).utc().format(),
            dateToUTC: moment(selectInfo.endStr).utc().format(),
            projectID,
          },
          index: 0,
        })
      );
      dispatch(setWorkSessionMode("single"));
      dispatch(setFormOpen({ isOpen: true, formID: "workSession" }));
    }
  };

  const handleEventClick = (clickInfo) => {
    const event = clickInfo.event;

    if (event.id?.includes("time_off")) {
      dispatch(
        setSelectedMusicianHolidayID(parseInt(event.id.split("time_off")[1]))
      );
      dispatch(setFormOpen({ isOpen: true, formID: "musicianHoliday" }));
      return;
    }
    const workSession = workSessions.find((w) => w.id === parseInt(event.id));
    if (workSession?.projectVersion === 2) {
      navigate(
        `${RouterConfig.projects}/${workSession.projectID}/mission-control?workSessionID=${workSession?.id}`
      );
    } else {
      dispatch(setSelectedWorkSessionID(workSession.id));
    }
  };

  const firstDay = parseInt(settings.calendarFirstDay ?? "0");

  function getInitialDate() {
    if (today) return moment().toDate();
    // any upcoming?
    const upcomings = workSessions.filter((w) =>
      moment(w.dateFromUTC).isAfter(moment())
    );
    if (upcomings.length > 0) {
      return moment(upcomings[0].dateFromUTC).toDate();
    }

    const _workSessions = JSON.parse(JSON.stringify(workSessions));
    _workSessions.sort(
      (a, b) =>
        moment(a.dateFromUTC).valueOf() - moment(b.dateFromUTC).valueOf()
    );

    if (_workSessions.length > 0) {
      return moment(_workSessions[0].dateFromUTC).toDate();
    }

    return moment().toDate();
  }

  function getSelectedDate() {
    const calendarEl: any = calendarRef.current;
    return calendarEl?.getApi().view.title;
  }

  return (
    <Box
      className="prevent-select"
      sx={{
        flex: 1,
        display: "flex",
        gap: 1,
        flexDirection: "column",
        minHeight: 800,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", gap: 1 }}>
          <Button
            onClick={() => {
              const calendarEl: any = calendarRef.current;
              calendarEl?.getApi()?.today();
              setSelectedDate(getSelectedDate());
            }}
            size="sm"
            color="neutral"
            variant="soft"
          >
            Today
          </Button>
          <IconButton
            onClick={() => {
              const calendarEl: any = calendarRef.current;
              calendarEl?.getApi()?.prev();
              setSelectedDate(getSelectedDate());
            }}
            size="sm"
            color="neutral"
            variant="soft"
          >
            <i className="fa-regular fa-chevron-left"></i>
          </IconButton>
          <IconButton
            onClick={() => {
              const calendarEl: any = calendarRef.current;
              calendarEl?.getApi()?.next();
              setSelectedDate(getSelectedDate());
            }}
            size="sm"
            color="neutral"
            variant="soft"
          >
            <i className="fa-regular fa-chevron-right"></i>
          </IconButton>
        </Box>
        <Box sx={{ textAlign: "center" }}>
          <Typography level="body2">{getSelectedDate()}</Typography>
          <Typography level="body4">{tzName}</Typography>
        </Box>
        <Tabs
          defaultValue={"week"}
          onChange={(e, v) => {
            const calendarEl: any = calendarRef.current;
            switch (v) {
              case "month":
                calendarEl?.getApi()?.changeView("dayGridMonth");
                setSelectedDate(getSelectedDate());
                break;
              case "week":
                calendarEl?.getApi()?.changeView("timeGridWeek");
                setSelectedDate(getSelectedDate());
                break;
            }
          }}
          size="sm"
          variant="soft"
          sx={{ width: 200, borderRadius: 3 }}
        >
          <TabList variant="outlined" color="neutral">
            <Tab value="month">Month</Tab>
            <Tab value="week">Week</Tab>
          </TabList>
        </Tabs>
      </Box>
      <Box sx={{ flexGrow: 1, position: "relative" }}>
        <FullCalendar
          height="100%"
          plugins={
            readOnly
              ? [dayGridPlugin, timeGridPlugin]
              : [dayGridPlugin, timeGridPlugin, interactionPlugin]
          }
          headerToolbar={{
            left: "",
            center: "",
            right: "",
          }}
          // datesSet={(e) => {
          //   if (!today) dispatch(setCalendarOptions(e));
          // }}
          ref={calendarRef}
          weekNumbers
          initialDate={getInitialDate()}
          initialView={calendarOptions?.view?.type ?? "timeGridWeek"}
          firstDay={firstDay}
          selectable={
            !musicianID && !venueID && !customerID && !payrollID && !readOnly
          }
          selectMirror
          dayMaxEvents
          allDaySlot={musicianID && musicianHolidays?.length > 0 ? true : false}
          weekends
          events={getEventsFromWorkSession()}
          select={!readOnly ? handleDateSelect : undefined}
          eventContent={(e) => <RenderEventContent eventInfo={e} />}
          eventClick={handleEventClick}
          eventChange={
            projectID && !readOnly
              ? (e) => dispatch(setRescheduleEvent(e))
              : undefined
          }
          eventDurationEditable={false}
        />
      </Box>
    </Box>
  );
}

let eventGuid = 0;

export function createEventId() {
  return String(eventGuid++);
}

export const RenderEventContent = ({ eventInfo }) => {
  // const workSessionID = parseInt(eventInfo.event.id);
  // const { workSession } = useWorkSession(workSessionID);

  const monthView = eventInfo?.view?.type === "dayGridMonth" ?? false;

  return (
    <Box
      sx={{
        overflow: "hidden",
        width: "100%",
        background: monthView && alpha(eventInfo.backgroundColor, 0.2),
        borderLeft: monthView && `solid 2px ${eventInfo.backgroundColor}`,
        pl: monthView && 1,
        cursor: "pointer",
      }}
    >
      <b>{eventInfo.timeText}</b>
      <br />
      <i>{eventInfo.event.title}</i>
      <br />
      {/* {workSession ? <MercurySessionGlance workSession={workSession} /> : []} */}
    </Box>
  );
};
