import {
  Alert,
  Avatar,
  Button,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/joy";
import {
  Box,
  Drawer,
  Grow,
  Hidden,
  Paper,
  Portal,
  Snackbar,
  alpha,
} from "@mui/material";
import { useTour } from "@reactour/tour";
import { Stage, Stage_Entity } from "entities/stage";
import { StackedMusicianAvatar } from "features/chair/v2/position";
import { useAskQuestion } from "features/context/AskQuestion/AskQuestion";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  FormID,
  formOpenMapSelector,
  selectedProjectIDSelector,
  setFormOpen,
  setNotifier2,
} from "reducers/rhapsody";
import {
  layoutInternalSelector,
  layoutUnselectAll,
  layoutUtilsSelector,
  notifyModeSelector,
  positionsSelectedSelector,
  setNotifyMode,
} from "reducers/v2/missionControl";
import { useCompanySettings } from "redux/company/companyHooks";
import { useProjectMercury2 } from "redux/projectMercury/projectMercuryHooks";

export default function GroupNotify() {
  const { visibleAssignments, visibleMusicians, visibleChairIDs } = useSelector(
    positionsSelectedSelector
  );
  const internal = useSelector(layoutInternalSelector);
  const utils = useSelector(layoutUtilsSelector);
  const selecto = useSelector(positionsSelectedSelector);
  const dispatch = useDispatch();
  const projectID = useSelector(selectedProjectIDSelector);
  const tour = useTour();
  const formOpenMap = useSelector(formOpenMapSelector);
  const notifyMode = useSelector(notifyModeSelector);
  const [scrollable, setIsScrollable] = useState(false);
  const settings = useCompanySettings();
  const nudge = notifyMode === "nudge";
  const ref = useRef();
  const query = useProjectMercury2(
    {
      id: projectID,
      body: {
        musicianIDs: selecto.visibleMusicianIDs,
        nudge,
      },
    },
    null,
    settings
  );

  const askQuestion = useAskQuestion();

  const { hProject, isFetching, isLoading } = query;

  useEffect(() => {
    checkScroll();
  }, [ref, hProject]);

  const checkScroll = () => {
    if (ref?.current) {
      const scrollContainer: HTMLDivElement = ref.current;
      console.log(scrollContainer.scrollWidth, scrollContainer.clientWidth);
      if (scrollContainer.scrollWidth > scrollContainer.clientWidth) {
        setIsScrollable(true);
      } else {
        setIsScrollable(false);
      }
    }
  };

  let otherDialogOpened = false;
  for (const key in formOpenMap) {
    if (Object.prototype.hasOwnProperty.call(formOpenMap, key)) {
      const open = formOpenMap[key];
      const k = key as FormID;
      if (k !== "projectSidebar" && open) otherDialogOpened = true;
    }
  }
  const stages = (
    (nudge
      ? hProject?.currentStages.filter((s) => s.nudgeable)
      : hProject?.upcomingStages) ?? []
  ).filter((j) => {
    let find = false;
    console.log(j.name, j.jobIDs);
    j.jobIDs?.forEach((mercuryJobID) => {
      console.log("mercuryJobID", mercuryJobID);
      const job = hProject.jobs.find((j) => j.id === mercuryJobID);
      console.log("job", job);
      const musician = visibleMusicians.find((m) => m.id === job?.refID);
      console.log("musician", musician);
      if (musician) find = true;
    });
    return find;
  });

  const handleSend = (s: Stage_Entity) => {
    const duplicateIDs = internal.duplicateMusicianIDs.filter((value) =>
      selecto.visibleMusicianIDs.includes(value)
    );
    if (duplicateIDs.length) {
      const firstMusicianID = duplicateIDs[0];
      return askQuestion("Heads up!", ["Got it"], {
        subtitle: (
          <Typography>
            You're trying to invite{" "}
            <Typography level="body2" sx={{ fontWeight: 600, color: "black" }}>
              {utils.musiciansMap[firstMusicianID]?.fullName()}
            </Typography>{" "}
            who seats on multiple chairs. You need to fix this before hitting
            send.
          </Typography>
        ),
      });
    }
    tour.setIsOpen(false);
    dispatch(
      setNotifier2({
        hProject,
        nudge,
        selectedStageID: s.id,
      })
    );
    dispatch(setFormOpen({ formID: "notifier2", isOpen: true }));
  };

  const content = (
    <Box
      ref={ref}
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1,
        flexShrink: 0,
        pr: 2,
      }}
    >
      {stages.length === 0 ? (
        <Typography level="body2">
          Looks like there is nobody to notify
        </Typography>
      ) : (
        []
      )}
      {stages
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((s) => (
          <Tooltip
            variant="outlined"
            arrow
            size="sm"
            key={s.id}
            title={
              utils.workSessions.length === 0
                ? "Create Work Session first"
                : undefined
            }
          >
            <Button
              variant="soft"
              sx={{
                background: alpha(s.color, 0.2),
                color: s.color,
                "&:hover": {
                  background: alpha(s.color, 0.3),
                },
                "&:active": {
                  background: alpha(s.color, 0.4),
                },
                cursor:
                  utils.workSessions.length === 0 ? "not-allowed" : undefined,
                opacity: utils.workSessions.length === 0 ? 0.4 : 1,
              }}
              onClick={
                utils.workSessions.length ? () => handleSend(s) : undefined
              }
            >
              <Box sx={{ textAlign: "left" }}>
                <Typography
                  level="body2"
                  sx={{ color: "inherit", fontWeight: 600 }}
                  startDecorator={<img src={s.icon} style={{ height: 16 }} />}
                >
                  {nudge ? utils.stagesMap[s.id]?.wording() : s.name}
                </Typography>
                <Typography
                  sx={{ color: "inherit", opacity: 0.58 }}
                  level="body4"
                >
                  {s.jobIDs.length} Musician{s.jobIDs.length > 1 ? "s" : ""}
                </Typography>
              </Box>
            </Button>
          </Tooltip>
        ))}
    </Box>
  );

  const open =
    visibleAssignments.length > 0 &&
    !otherDialogOpened &&
    !isLoading &&
    !isFetching;

  return (
    <Portal>
      <Hidden mdDown>
        <Snackbar
          open={open}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          {open ? (
            <Paper
              ref={ref}
              className="tour-notifier"
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                borderRadius: 50,
                // maxWidth: "calc(100vw - 32px)",
                maxWidth: 920,
                overflowY: "auto",
                justifyContent: "space-between",
                boxShadow: "0px 15px 50px rgba(0, 0, 0, 0.3)",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  top: -28,
                  width: "100%",
                  display: "flex",
                  gap: 0.5,
                  justifyContent: "center",
                }}
              >
                <Alert
                  variant={nudge ? "outlined" : "solid"}
                  color={!nudge ? "primary" : "neutral"}
                  size="sm"
                  onClick={() => dispatch(setNotifyMode("communication"))}
                  sx={{
                    p: 0.5,
                    gap: 2,
                    background: nudge ? "white" : undefined,
                    cursor: "pointer",
                    borderBottomRightRadius: 0,
                    borderBottomLeftRadius: 0,
                  }}
                >
                  <Typography
                    endDecorator={
                      <i className="fa-sharp fa-solid fa-paper-plane"></i>
                    }
                    level="body3"
                    sx={{ color: !nudge ? "white" : undefined }}
                  >
                    Communication
                  </Typography>
                </Alert>
                <Alert
                  onClick={() => dispatch(setNotifyMode("nudge"))}
                  variant={!nudge ? "outlined" : "solid"}
                  color={nudge ? "primary" : "neutral"}
                  size="sm"
                  sx={{
                    p: 0.5,
                    cursor: "pointer",
                    background: !nudge ? "white" : undefined,
                    gap: 2,
                    borderBottomRightRadius: 0,
                    borderBottomLeftRadius: 0,
                  }}
                >
                  <Typography
                    endDecorator={<i className="fa-solid fa-bullhorn"></i>}
                    level="body3"
                    sx={{ color: nudge ? "white" : undefined }}
                  >
                    Nudge
                  </Typography>
                </Alert>
              </Box>
              <Box
                sx={{
                  position: "sticky",
                  left: 0,
                  p: 2,
                  display: "flex",
                  gap: 1,
                  alignItems: "center",
                  background: "white",
                  zIndex: 999,
                }}
              >
                <StackedMusicianAvatar
                  musiciansWithPictureFirst
                  opacity
                  musicians={visibleMusicians}
                />
                <Box>
                  <Typography level="h6" noWrap>
                    {visibleMusicians.length} Musician
                    {visibleMusicians.length > 1 ? "s" : ""}
                  </Typography>
                  <Typography
                    level="body4"
                    noWrap
                    endDecorator={<i className="fa-solid fa-arrow-right"></i>}
                  >
                    Select an Event to send
                  </Typography>
                </Box>
              </Box>
              <Divider orientation="vertical" />
              {content}
              <Box
                sx={{
                  position: "sticky",
                  background:
                    "linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 20%)",
                  p: 1,
                  pl: 2,
                  right: 0,
                  display: "fex",
                  alignItems: "center",
                  gap: 0.5,
                }}
              >
                {scrollable ? (
                  <Tooltip size="sm" title="Scroll to last">
                    <IconButton
                      onClick={() => {
                        const div: HTMLDivElement = ref?.current;
                        div?.scrollTo({
                          left: div.scrollWidth,
                          behavior: "smooth",
                        });
                      }}
                      sx={{ borderRadius: 50 }}
                      size="sm"
                      variant="plain"
                      color="neutral"
                    >
                      <i className="fa-solid fa-chevron-right"></i>
                    </IconButton>
                  </Tooltip>
                ) : (
                  []
                )}
                <Tooltip size="sm" title="Unselect All [esc]">
                  <Avatar
                    sx={{
                      cursor: "pointer",
                      "&:hover": {
                        background: "#eeeeee",
                      },
                    }}
                    onClick={() => dispatch(layoutUnselectAll())}
                    color="neutral"
                    variant="soft"
                  >
                    <i className="fa-solid fa-xmark"></i>
                  </Avatar>
                </Tooltip>
              </Box>
            </Paper>
          ) : (
            <Box />
          )}
        </Snackbar>
      </Hidden>
      <Hidden mdUp>
        <Grow in={visibleChairIDs.length > 0}>
          <Drawer anchor="bottom" variant="persistent" open>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                p: 1,
              }}
            >
              {content}
            </Box>
          </Drawer>
        </Grow>
      </Hidden>
    </Portal>
  );
}
