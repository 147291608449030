import { show } from "@intercom/messenger-js-sdk";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  FormLabel,
  Input,
  ListItemDecorator,
  Menu,
  MenuItem,
  Sheet,
  Tab,
  TabList,
  Tabs,
  Textarea,
  Tooltip,
  Typography,
} from "@mui/joy";
import { DialogContent, Hidden, Skeleton } from "@mui/material";
import { TimeoutId } from "@reduxjs/toolkit/dist/query/core/buildMiddleware/types";
import DialogClose from "atoms/DialogClose/DialogClose";
import { REACT_APP_API } from "config";
import { Musician } from "entities/musician";
import { Project_Entity } from "entities/project";
import MusicianAvatar from "features/musicians/MusicianAvatar";
import { mapToArray } from "helpers";
import { RouterConfig } from "hooks/AppRouter/AppRouter";
import ProjectNotesPicker from "hooks/ProjectNotesPicker";
import { ProjectRostersPicker } from "hooks/ProjectRostersPicker/ProjectRostersPicker";
import SlidingDialog from "hooks/SlidingDialog/SlidingDialog";
import {
  ProjectContacts,
  ProjectCover,
  ProjectDressCode,
  ProjectWage,
} from "hooks/projectSettings/projectSettings";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  formOpenSelector,
  notifier2Selector,
  notifierBodySelector,
  resetNotifierBody,
  selectedProjectIDSelector,
  setFormOpen,
  setNotifierBody,
  setNotifierSelectedStage,
  setSelectedProjectID,
} from "reducers/rhapsody";
import {
  layoutInternalSelector,
  layoutUtilsSelector,
  positionsSelectedSelector,
} from "reducers/v2/missionControl";
import { useUpdateAssignmentMutation } from "redux/assignment/assignmentEndpoints";
import { useCurrentCompany } from "redux/company/companyHooks";
import { useProjectLinks } from "redux/link/linkHooks";
import { useUpdateProjectMutation } from "redux/project/projectEndpoints";
import { useProject } from "redux/project/projectHooks";
import { useProjectContacts } from "redux/projectContact/projectContactHooks";
import { useProjectNotes } from "redux/projectNote/projectNoteHooks";
import { useLazyGetProjectNotifyQuery } from "redux/projectNotify/hiringNotifyEndpoints";
/**
 *
 * @returns {ReactElement} Notifier page
 */
export function Notifier2() {
  const open = useSelector(formOpenSelector("notifier2"));
  const notifier2 = useSelector(notifier2Selector);
  const projectID: number = useSelector(selectedProjectIDSelector);
  const { project: _project } = useProject(projectID);
  const [sendCall] = useLazyGetProjectNotifyQuery();
  const { company } = useCurrentCompany();
  const selecto = useSelector(positionsSelectedSelector);
  const utils = useSelector(layoutUtilsSelector);
  const { projectPieces } = utils;
  const internal = useSelector(layoutInternalSelector);
  const { callSent } = internal;
  const [anchorEl, setAnchorEl] = useState<
    (EventTarget & HTMLButtonElement) | null
  >();
  const { links } = useProjectLinks(projectID);
  const { projectContacts } = useProjectContacts(projectID);
  const [preview, setPreview] = useState<string>();
  const [acknowledge1, setAcknowledge1] = useState<boolean>(false);
  const [acknowledge2, setAcknowledge2] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [musicianIDForPreview, setMusicianIDForPreview] = useState<number>();
  const [project, setProject] = useState<Project_Entity | null>();
  const [updateProject] = useUpdateProjectMutation();
  const [mobileTab, setMobileTab] = useState<"info" | "preview">("info");
  const [previewTimeout, setPreviewTimeout] = useState<{
    timeoutID: TimeoutId;
    time: number;
  } | null>();
  const ref = useRef();
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);
  const projectPage = pathnames.indexOf(RouterConfig.projects.slice(1)) >= 0;
  const dispatch = useDispatch();
  const { projectNotes } = useProjectNotes(projectID);
  const body = useSelector(notifierBodySelector);
  const stages = (
    (notifier2.nudge
      ? notifier2.hProject?.currentStages.filter((s) => s.nudgeable)
      : notifier2.hProject?.upcomingStages) ?? []
  ).filter((j) => j.jobIDs?.length);
  const selectedStage = stages.find((s) => s.id === notifier2.selectedStageID);

  useEffect(() => {
    if (callSent) {
      setAcknowledge2(true);
    }
  }, [callSent]);

  useEffect(() => {
    if (selectedStage && selectedStage?.config) {
      try {
        const config = JSON.parse(selectedStage?.config);
        dispatch(
          setNotifierBody({
            ...body,
            greeting: `<p class="ql-align-center">${config.greeting}</p>`,
          })
        );
      } catch (error) {}
    }
  }, [notifier2]);

  const hJobs = notifier2.hProject.jobs.filter((j) =>
    selectedStage?.jobIDs.includes(j.id)
  );

  const musicianForPreview = utils.musiciansMap[musicianIDForPreview];

  const musicianIDs = hJobs.reduce((a, v) => {
    if (!a.includes(v.refID)) a.push(v.refID);
    return a;
  }, []);

  const musicians: Musician[] = musicianIDs.reduce((a, v) => {
    a.push(utils.musiciansMap[v]);
    return a;
  }, []);

  const workSessions = mapToArray(utils.workSessionsMap);
  const expired = workSessions?.reduce((a, i) => {
    return a || moment(i.dateToUTC).isBefore(moment());
  }, false);

  useEffect(() => {
    return () => {
      if (!projectPage) {
        dispatch(setSelectedProjectID());
        dispatch(setNotifierSelectedStage(null));
      }
    };
  }, []);

  useEffect(() => {
    if (_project?.id) {
      setProject(_project);
      getHTMLPreview();
    }
  }, [_project, projectContacts, projectPieces, utils, links]);

  useEffect(() => {
    if (previewTimeout) {
      clearTimeout(previewTimeout.timeoutID);
    }
    const time = new Date().getTime();
    const timeoutID = setTimeout(() => {
      getHTMLPreview();
      setPreviewTimeout(null);
    }, 500);
    setPreviewTimeout({
      time,
      timeoutID,
    });
  }, [body, musicianIDForPreview, projectNotes]);

  useEffect(() => {
    if (musicians.length) {
      setMusicianIDForPreview(musicians[0].id);
    }
  }, [notifier2]);

  useEffect(() => {
    if (!open) dispatch(resetNotifierBody());
  }, [open]);

  const notify = () => {
    const _b = {
      ...body,
      ...projectNotifyBody,
      nudge: notifier2.nudge,
      mercuryStageID: notifier2.selectedStageID,
    };
    sendCall({
      projectID,
      body: _b,
    });
    onClose();
  };

  const onClose = () => {
    dispatch(setNotifierBody({ ...body, greeting: "" }));
    dispatch(setFormOpen({ isOpen: false, formID: "notifier2" }));
  };

  const projectNotifyBody = {
    musicianIDs: selecto.visibleMusicianIDs,
  };

  const getHTMLPreview = async () => {
    if (!musicianIDForPreview) return;
    setLoading(true);
    const _b: any = {
      ...body,
      ...projectNotifyBody,
      nudge: notifier2.nudge,
      musician: { id: musicianIDForPreview },
    };
    _b.mercuryStageID = notifier2.selectedStageID;
    const isV3 =
      notifier2.hProject.jobs.find((j) => j.refID === musicianIDForPreview)
        ?.version === 3;

    fetch(
      `${REACT_APP_API}/rhapsody/${isV3 ? "tags" : "projects"}/${
        isV3 ? project.tags[0].id : projectID
      }/mercury/html`,
      {
        body: JSON.stringify(_b),
        credentials: "include",
        headers: {
          "content-type": "application/json",
        },
        method: "PUT",
      }
    )
      .then((t) => t.text())
      .then((t) => {
        setPreview(t);
        setLoading(false);
      });
  };

  const musicianForPreviewDropdown = (
    <Menu
      size="sm"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={() => setAnchorEl(null)}
      sx={{ zIndex: 9999, maxHeight: 600 }}
    >
      {musicians
        .filter((m) => m.id !== musicianIDForPreview)
        .map((m) => (
          <MenuItem
            onClick={() => {
              setMusicianIDForPreview(m.id);
              setAnchorEl(null);
            }}
            sx={{ gap: 1 }}
            key={m.id}
          >
            <ListItemDecorator>
              <MusicianAvatar size={26} noBadge musician={m} />
            </ListItemDecorator>
            {m.fullName()}
          </MenuItem>
        ))}
    </Menu>
  );

  const callPreview = selectedStage && project && company && (
    <Sheet
      variant="soft"
      color="neutral"
      sx={{
        flex: 1,
        flexShrink: 0,
        position: "relative",
        display: "flex",
        flexDirection: "column",
        gap: 1,
        background: "#F2F5F9",
      }}
    >
      <Box sx={{ p: 1 }}>
        <Typography level="body3" startDecorator="To:">
          <Chip
            startDecorator={
              <MusicianAvatar
                size={20}
                noBadge
                musicianID={musicianIDForPreview}
              />
            }
            size="sm"
            color="neutral"
          >
            {musicianForPreview?.fullName()} ({musicianForPreview?.email})
          </Chip>
          {musicians.length > 1 ? (
            <>
              <Chip
                onClick={(e) => {
                  setAnchorEl(e.currentTarget);
                }}
                sx={{
                  cursor: "pointer",
                  "&:hover": {
                    background: "rgba(155,155,155,0.2)",
                  },
                }}
                size="sm"
                color="neutral"
                variant="outlined"
              >
                {musicians.length - 1 >= 1
                  ? ` +${musicians.length - 1} more ▾`
                  : ""}
              </Chip>
              {musicianForPreviewDropdown}
            </>
          ) : (
            []
          )}
        </Typography>
        <Typography level="body3" startDecorator="From:">
          {body.messenger || company?.name || "Rhapsody Bot"}
        </Typography>
        <Typography level="body3" startDecorator="Reply To:">
          {company?.email}
        </Typography>
        <Typography level="body3" startDecorator="Subject:">
          {/* {jobForPreview?.channels?.find((c) => c.name === "Email")?.subject ??
            ""} */}
          {selectedStage?.channels?.find((c) => c.name === "Email")?.subject ??
            ""}
        </Typography>
      </Box>
      <Divider>Email Preview</Divider>
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          position: "relative",
          overflow: "auto",
        }}
      >
        {preview ? (
          <iframe
            title="preview"
            ref={ref}
            srcDoc={preview}
            style={{
              border: "none",
              width: "100%",
              flex: 1,
            }}
          />
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              flex: 1,
              p: 2,
            }}
          >
            <Skeleton />
            <Skeleton />
          </Box>
        )}
        <Box
          sx={{
            backdropFilter: loading ? "blur(5px)" : "blur(0px)",
            height: "100%",
            width: "100%",
            position: "absolute",
            top: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            transition: "backdrop-filter .2s",
            pointerEvents: "none",
          }}
        >
          {loading ? <CircularProgress /> : []}
        </Box>
      </Box>
    </Sheet>
  );

  const callInfo = selectedStage && project && (
    <Sheet
      variant="plain"
      color="neutral"
      sx={{
        p: 2,
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        gap: 2,
        overflow: "auto",
        minHeight: "auto",
        height: 0,
      }}
    >
      <Typography
        sx={{ color: selectedStage.color }}
        startDecorator={<img src={selectedStage.icon} height={25} />}
        level="h6"
      >
        {selectedStage.name}
      </Typography>
      <ProjectCover />
      <Input
        onChange={(e) =>
          dispatch(setNotifierBody({ ...body, messenger: e.target.value }))
        }
        startDecorator="Sender:"
        placeholder=""
      />
      <Box sx={{ height: 200, position: "relative" }}>
        <FormLabel>Greetings</FormLabel>
        <ReactQuill
          theme="snow"
          value={body.greeting}
          onChange={(e) => {
            dispatch(setNotifierBody({ ...body, greeting: e }));
          }}
          modules={{
            toolbar: [
              ["bold", "italic", "underline", "strike"],
              [{ list: "ordered" }, { list: "bullet" }],
              [{ align: [] }],
              [{ color: [] }],
              ["clean"], // Remove formatting button
            ],
            clipboard: {
              // toggle to add extra line breaks when pasting HTML:
              matchVisual: false,
            },
          }}
        />
      </Box>
      <AssignmentMemo musicianID={musicianIDForPreview} />
      <Box sx={{ display: "flex", gap: 2 }}>
        <Checkbox
          size="sm"
          label="Include Details"
          variant="solid"
          checked={body.showSessionDetails}
          onChange={(e) => {
            dispatch(
              setNotifierBody({ ...body, showSessionDetails: e.target.checked })
            );
          }}
        />
      </Box>
      {body.showSessionDetails ? (
        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          <Checkbox
            size="sm"
            label="Show full schedule"
            variant="solid"
            checked={!project.hideWhenNotPlaying}
            onChange={(e) => {
              updateProject({
                id: projectID,
                body: { ...project, hideWhenNotPlaying: !e.target.checked },
              })
                .unwrap()
                .then(getHTMLPreview);
            }}
          />
          <Tooltip
            size="sm"
            arrow
            title="If enabled, musicians will see all the Work Sessions the Project
            contains (even the ones they are not in)."
          >
            <Typography level="body2">
              <i className="fa-regular fa-circle-question"></i>
            </Typography>
          </Tooltip>
        </Box>
      ) : (
        []
      )}
      <Divider>Options</Divider>
      {/* <ProjectEmailOtherOptions /> */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          // alignItems: "start",
        }}
      >
        <ProjectNotesPicker
          value={body?.projectNoteIDs}
          onChange={(projectNoteIDs: number[]) => {
            dispatch(setNotifierBody({ ...body, projectNoteIDs }));
          }}
        />
        <ProjectRostersPicker
          onChange={(e) => {
            dispatch(setNotifierBody({ ...body, projectRosterIDs: e }));
          }}
        />
        <ProjectWage />
        <ProjectDressCode />
        <ProjectContacts />
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <Box
        sx={{
          p: 1,
          pb: "30px",
          display: "flex",
          flexDirection: "column",
          gap: 2,
          position: "sticky",
          bottom: 0,
          backdropFilter: "blur(10px)",
          background: "rgba(255,255,255,0.2)",
          borderTop: "solid 1px rgba(155,155,155,0.3)",
        }}
      >
        <Checkbox
          size="sm"
          onChange={(e) => setAcknowledge1(e.target.checked)}
          checked={acknowledge1}
          sx={{ alignItems: "center", gap: 2 }}
          label={
            <Typography level="body2">
              I understand that this action will send emails/SMS. I checked all
              the information concerning the Project and the related Work
              Session(s). <br />
            </Typography>
          }
          variant="solid"
          defaultChecked
        />
        {!callSent ? (
          <Checkbox
            size="sm"
            onChange={(e) => setAcknowledge2(e.target.checked)}
            checked={acknowledge2}
            sx={{ alignItems: "center", gap: 2 }}
            label={
              <Typography level="body2">
                I understand that I won't be able to add more Work Sessions to
                this Project after this action.
              </Typography>
            }
            variant="solid"
            defaultChecked
          />
        ) : (
          []
        )}
        <Button
          fullWidth
          disabled={!acknowledge1 || !acknowledge2}
          onClick={notify}
        >
          Send Now!
        </Button>
      </Box>
    </Sheet>
  );

  const callBuilder = selectedStage && (
    <>
      <Hidden smUp>
        <Box sx={{ p: 1 }}>
          <Tabs
            size="sm"
            value={mobileTab}
            onChange={(e, v) => {
              setMobileTab(v as "info" | "preview");
            }}
          >
            <TabList variant="soft" color="neutral">
              <Tab value={"info"}>Info</Tab>
              <Tab value="preview">Preview</Tab>
            </TabList>
          </Tabs>
        </Box>
      </Hidden>
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {expired ? (
          <Alert
            color="warning"
            sx={{ borderRadius: 0, borderBottom: "solid 1px #492E0A22" }}
          >
            <Box sx={{ textAlign: "center", width: "100%" }}>
              <b>
                <i className="fa-solid fa-triangle-exclamation"></i> WARNING:
              </b>{" "}
              Looks like you're trying to send a communication about a Work
              Session that has already taken place.
            </Box>
          </Alert>
        ) : (
          []
        )}
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            position: "relative",
            alignItems: "stretch",
          }}
        >
          <Hidden smDown>
            <Box sx={{ width: "50%", display: "flex" }}>{callPreview}</Box>
            <Box sx={{ width: "50%", display: "flex", flexFlow: "column" }}>
              {callInfo}
            </Box>
          </Hidden>
          <Hidden smUp>
            {mobileTab === "preview" ? callPreview : callInfo}
          </Hidden>
        </Box>
      </Box>
    </>
  );

  const DialogComponent = selectedStage ? SlidingDialog : DialogClose;

  const stageNotFound = (
    <DialogContent>
      <Typography level="h6">Stage not found</Typography>
      <Typography>
        Looks like the Mercury Pipeline doesn't include the desired stage.
        Contact us if you need more assistance.
      </Typography>
      <Button
        sx={{ mt: 2 }}
        startDecorator={<i className="fa-solid fa-comment"></i>}
        onClick={() => {
          show();
        }}
      >
        Chat with us
      </Button>
    </DialogContent>
  );

  const getContent = () => {
    if (!selectedStage) return stageNotFound;
    if (selectedStage) return callBuilder;
  };

  return (
    <DialogComponent open={open} onClose={onClose}>
      {getContent()}
    </DialogComponent>
  );
}

function AssignmentMemo({ musicianID }: { musicianID: number }) {
  const utils = useSelector(layoutUtilsSelector);
  const [assignmentMemo, setAssignmentMemo] = useState("");
  const [updateAssignment] = useUpdateAssignmentMutation();
  const musician = utils.musiciansMap[musicianID];
  const assignment = utils.assignments.find((a) => a.musicianID === musicianID);

  useEffect(() => {
    if (assignment) {
      setAssignmentMemo(assignment.memo ?? "");
    }
  }, [musicianID]);

  const save = () => {
    updateAssignment({
      id: assignment.id,
      body: { memo: assignmentMemo },
    });
  };

  return (
    <FormControl>
      <FormLabel>Assignment Memo for {musician.fullName()}</FormLabel>
      <Textarea
        value={assignmentMemo}
        onChange={(e) => setAssignmentMemo(e.target.value)}
        placeholder={"Any info to add for this assignment?"}
        minRows={2}
        onBlur={save}
      />
      <Typography color={"neutral"} level="body4">
        Only {musician.fullName()} will see this
      </Typography>
    </FormControl>
  );
}
