import { Box } from "@mui/material";
import { Dictionary } from "@reduxjs/toolkit";
import { Assignment } from "entities/assignment";
import { Family } from "entities/family";
import { HProject } from "entities/hProject";
import { Instrument } from "entities/instrument";
import { getInternal } from "entities/internal/helper";
import { Link } from "entities/link";
import { Musician } from "entities/musician";
import { MusicianHoliday } from "entities/musicianHoliday";
import { Piece } from "entities/piece";
import { Pipeline } from "entities/pipeline";
import { Project } from "entities/project";
import { ProjectNote } from "entities/projectNote";
import { ProjectPiece } from "entities/projectPiece";
import { ProjectRoster } from "entities/projectRoster";
import { RhapsodyChair, RhapsodyChair_Entity } from "entities/rhapsodyChair";
import { Section } from "entities/section";
import { SectionRole } from "entities/sectionRole";
import { Stage } from "entities/stage";
import { WorkSession } from "entities/workSession";
import { WorkSessionProjectPiece } from "entities/workSessionProjectPiece";
import { arrayToMap } from "helpers";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectedProjectIDSelector } from "reducers/rhapsody";
import {
  layoutSetInternal,
  layoutSetUtils,
  layoutUtilsSelector,
} from "reducers/v2/missionControl";
import { useAssignments } from "redux/assignment/assignmentHooks";
import { useFamilies } from "redux/family/familyHooks";
import { useInstruments } from "redux/instrument/instrumentHooks";
import { useProjectLinks } from "redux/link/linkHooks";
import { useLazyGetMusiciansQuery } from "redux/musician/musicianEndpoints";
import { useMusiciansForAssignments } from "redux/musician/musicianHooks";
import { useMusicianHolidays } from "redux/musicianHoliday/musicianHolidayHooks";
import { usePieces } from "redux/piece/pieceHooks";
import { usePipeline } from "redux/pipeline/pipelineHooks";
import { useProject } from "redux/project/projectHooks";
import { useProjectMercury2 } from "redux/projectMercury/projectMercuryHooks";
import { useProjectNotes } from "redux/projectNote/projectNoteHooks";
import { useProjectPieces } from "redux/projectPiece/projectPieceHooks";
import { useProjectRosters } from "redux/projectRoster/projectRosterHooks";
import { useChairs } from "redux/rhapsodyChair/rhapsodyChairHooks";
import { useSections } from "redux/section/sectionHooks";
import { useSectionRoles } from "redux/sectionRole/sectionRoleHooks";
import { useStages } from "redux/stage/stageHooks";
import { useProjectWorkSessions } from "redux/workSession/workSessionHooks";
import { useWorkSessionProjectPieces } from "redux/workSessionProjectPiece/workSessionProjectPieceHooks";

export function Utils() {
  const dispatch = useDispatch();
  const projectID = useSelector(selectedProjectIDSelector);
  const { project } = useProject(projectID);
  const { familiesMap } = useFamilies({ skip: !projectID });
  const { links } = useProjectLinks(projectID);
  const { projectRosters } = useProjectRosters(projectID);
  const { projectNotes } = useProjectNotes(projectID);
  const {
    assignments,
    assignmentsMap,
    isLoading: l1,
  } = useAssignments(projectID);
  const { musiciansMap, isLoading: l2 } =
    useMusiciansForAssignments(assignments);
  const { chairs, chairsMap, isLoading: l3 } = useChairs(projectID);
  const { sectionsMap, isLoading: l4 } = useSections({ skip: !projectID });
  const {
    projectPieces,
    projectPiecesMap,
    isLoading: l5,
  } = useProjectPieces(projectID);
  const { instrumentsMap, isLoading: l6 } = useInstruments({
    skip: !projectID,
  });
  const {
    sectionRolesMap,
    sectionRoles,
    isLoading: l7,
  } = useSectionRoles({
    skip: !projectID,
  });

  const musicianIDs = chairs.reduce((a, v) => {
    if (!a.includes(v.musicianID)) a.push(v.musicianID);
    return a;
  }, []);

  const { piecesMap, isLoading: l8 } = usePieces(
    {
      filters: JSON.stringify([
        {
          name: "pieces.id",
          comparison: "in",
          value: projectPieces?.reduce((a, v) => {
            a.push(v.pieceID);
            return a;
          }, []),
        },
      ]),
    },
    { skip: projectPieces?.length === 0 || !projectID }
  );
  const {
    projectWorkSessionsMap,
    projectWorkSessions,
    isLoading: l9,
  } = useProjectWorkSessions(projectID);

  const { workSessionProjectPieces, isLoading: l10 } =
    useWorkSessionProjectPieces(projectID);

  const { stagesMap, isLoading: l11 } = useStages({
    skip: !projectID,
  });

  const {
    hProject,
    isLoading: l12,
    requestId,
    isFetching,
  } = useProjectMercury2(
    {
      id: projectID,
      body: {},
    },
    { skip: !projectID }
  );

  const { pipeline, isLoading: l13 } = usePipeline(project?.pipelineID);

  const { musicianHolidays, isLoading: l14 } = useMusicianHolidays(
    {
      filters: JSON.stringify([
        {
          name: "musician_holidays.musicianID",
          comparison: "in",
          value: musicianIDs,
        },
      ]),
    },
    { skip: projectPieces?.length === 0 || !projectID }
  );

  const _assignments = assignments;
  const releaseStage = pipeline?.stages?.find((s) => s.name === "Release");
  const releasedStage = pipeline?.stages?.find((s) => s.name === "Released");
  const revisionStage = pipeline?.stages?.find((s) => s.name === "Revision");
  const messageStage = pipeline?.stages?.find((s) => s.name === "Message");

  const layoutUtils: LayoutUtils = {
    releaseStage: releaseStage ? new Stage(releaseStage) : undefined,
    releasedStage: releaseStage ? new Stage(releasedStage) : undefined,
    revisionStage: revisionStage ? new Stage(revisionStage) : undefined,
    messageStage: messageStage ? new Stage(messageStage) : undefined,
    pipeline,
    project,
    hProject,
    chairs,
    chairsMap,
    musiciansMap,
    assignmentsMap: arrayToMap(_assignments),
    assignments: _assignments,
    sectionRolesMap,
    sectionRoles,
    workSessionsMap: projectWorkSessionsMap,
    workSessions: projectWorkSessions,
    workSessionProjectPieces,
    sectionsMap,
    familiesMap,
    projectPiecesMap,
    projectPieces,
    piecesMap,
    instrumentsMap,
    stagesMap,
    links,
    projectNotes,
    projectRosters,
    musicianHolidays,
  };

  useEffect(() => {
    const loading =
      l1 ||
      l2 ||
      l3 ||
      l4 ||
      l5 ||
      l6 ||
      l7 ||
      l8 ||
      l9 ||
      l10 ||
      l11 ||
      l12 ||
      l13 ||
      l14;

    if (!loading) {
      const internal = getInternal(layoutUtils);
      dispatch(layoutSetUtils(layoutUtils));
      dispatch(layoutSetInternal(internal));
    }
  }, [
    chairsMap,
    musiciansMap,
    assignmentsMap,
    sectionRolesMap,
    projectWorkSessionsMap,
    workSessionProjectPieces,
    sectionsMap,
    familiesMap,
    projectPiecesMap,
    piecesMap,
    instrumentsMap,
    stagesMap,
    project,
    links,
    projectNotes,
    projectRosters,
    pipeline,
    l1,
    l2,
    l3,
    l4,
    l5,
    l6,
    l7,
    l8,
    l9,
    l10,
    l11,
    l12,
    l13,
    isFetching,
    requestId,
  ]);

  return <Box sx={{ position: "absolute" }} />;
}

export function usePreviewChanges() {
  const utils = useSelector(layoutUtilsSelector);
  const [getMusicians] = useLazyGetMusiciansQuery();
  const dispatch = useDispatch();
  return async (chairEntities: RhapsodyChair_Entity[]) => {
    const chairs = RhapsodyChair.fromList(chairEntities);
    const chairsMap = arrayToMap(chairs);

    const musicianIDs = chairs.reduce((a, v) => {
      if (!a.includes(v.musicianID)) a.push(v.musicianID);
      return a;
    }, []);

    const query = await getMusicians({
      filters: JSON.stringify([
        {
          name: "musicians.id",
          comparison: "in",
          value: musicianIDs,
        },
      ]),
    }).unwrap();

    const musiciansMap = query.ids.reduce<Dictionary<Musician>>((a, v) => {
      a[parseInt(v.toString())] = new Musician(query.entities[v]);
      return a;
    }, {});

    const newUtils = { ...utils, chairs, chairsMap, musiciansMap };

    const internal = getInternal(newUtils);
    dispatch(layoutSetUtils(newUtils));
    dispatch(layoutSetInternal(internal));
  };
}

export type LayoutUtils = {
  project: Project;
  hProject: HProject;
  instrumentsMap: Dictionary<Instrument>;
  chairs: RhapsodyChair[];
  chairsMap: Dictionary<RhapsodyChair>;
  sectionRolesMap: Dictionary<SectionRole>;
  sectionRoles: SectionRole[];
  musiciansMap: Dictionary<Musician>;
  assignmentsMap: Dictionary<Assignment>;
  assignments: Assignment[];
  workSessionsMap: Dictionary<WorkSession>;
  workSessions: WorkSession[];
  workSessionProjectPieces: WorkSessionProjectPiece[];
  sectionsMap: Dictionary<Section>;
  familiesMap: Dictionary<Family>;
  projectPiecesMap: Dictionary<ProjectPiece>;
  stagesMap: Dictionary<Stage>;
  projectPieces: ProjectPiece[];
  piecesMap: Dictionary<Piece>;
  forWorkSessionID?: number;
  forProjectPieceID?: number;
  links: Link[];
  projectRosters: ProjectRoster[];
  projectNotes: ProjectNote[];
  pipeline: Pipeline;
  revisionStage?: Stage;
  releaseStage?: Stage;
  releasedStage?: Stage;
  messageStage?: Stage;
  musicianHolidays?: MusicianHoliday[];
};
