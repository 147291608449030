import Intercom from "@intercom/messenger-js-sdk";
import { useGetCurrentUserQuery } from "@nerdjs/account-kit";
import moment from "moment";
import { useEffect } from "react";
import { useCurrentSubscription } from "redux/subscription/subscriptionHooks";

/**
 *
 * @returns {ReactElement} IntercomConfig page
 */
export function IntercomConfig() {
  const { data: me } = useGetCurrentUserQuery();
  const superadmin = me?.services?.account?.indexOf("superadmin") >= 0;
  const { subscription } = useCurrentSubscription();

  useEffect(() => {
    const hideIntercom = localStorage.getItem("hideIntercom") === "true";
    if (me?.uuid && !hideIntercom) {
      const companies = me.organizations.reduce((a, v) => {
        if (!superadmin)
          a.push({
            id: v.id,
            name: v.name,
            plan: subscription?.productName,
            createdAt: v.id,
          });
        return a;
      }, []);

      const config = {
        app_id: "arj556zn",
        user_id: me.uuid, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
        name: `${me.firstName} ${me.lastName}`, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
        email: me.email, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
        created_at: moment(me.createdAt).unix(), // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
        companies: companies.length > 1 ? (companies as [any]) : undefined,
        company: companies.length === 1 ? companies[0] : undefined,
        hide_default_launcher: true,
      };
      Intercom(config);
    }
  }, [me]);

  return <></>;
}
