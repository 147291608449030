import { Instrument } from "entities/instrument";
import { Stage_Entity } from "entities/stage";
import { readyStage } from "entities/stage/helper";
import Musician_Entity from "./musician";
import { MusicianHoliday } from "entities/musicianHoliday";
import moment from "moment";
import { WorkSession } from "entities/workSession";
import { extendMoment } from "moment-range";

const momentRange = extendMoment(moment);
export default class Musician extends Musician_Entity {
  instruments: Instrument[];

  constructor(musiciansJSON: Musician_Entity) {
    super(musiciansJSON);
    if (musiciansJSON.instruments)
      this.instruments = Instrument.fromList(musiciansJSON.instruments);
  }

  static fromList(musiciansJSON: unknown): Array<Musician> {
    const musicians: Musician[] = [];
    if (musiciansJSON)
      Array.isArray(musiciansJSON) &&
        musiciansJSON.forEach((musicianJSON) => {
          musicians.push(new Musician(musicianJSON));
        });
    return musicians;
  }

  isOff(musicianHolidays: MusicianHoliday[]) {
    const now = moment();

    for (const key in musicianHolidays) {
      if (Object.prototype.hasOwnProperty.call(musicianHolidays, key)) {
        const h = musicianHolidays[key];
        if (h.musicianID === this.id) {
          const start = moment(h.dateFromUTC);
          const end = moment(h.dateToUTC);

          if (now.isSameOrAfter(start, "day") && now.isSameOrBefore(end, "day"))
            return true;
        }
      }
    }
    return false;
  }

  offUntil(musicianHolidays: MusicianHoliday[]) {
    const now = moment();

    for (const key in musicianHolidays) {
      if (Object.prototype.hasOwnProperty.call(musicianHolidays, key)) {
        const h = musicianHolidays[key];
        if (h.musicianID === this.id) {
          const start = moment(h.dateFromUTC);
          const end = moment(h.dateToUTC);

          if (now.isSameOrAfter(start, "day") && now.isSameOrBefore(end, "day"))
            return end.format("ll");
        }
      }
    }
    return null;
  }

  isOffFor(musicianHolidays: MusicianHoliday[], workSessions: WorkSession[]) {
    let overlaps = false;
    workSessions.forEach((workSession) => {
      musicianHolidays.forEach((h) => {
        if (h.musicianID === this.id) {
          const wStart = workSession.from();
          const wEnd = workSession.to();
          const wRange = momentRange.range(wStart, wEnd);

          const hStart = moment(h.dateFromUTC);
          const hEnd = moment(h.dateToUTC);
          const hRange = momentRange.range(hStart, hEnd);

          overlaps = wRange.overlaps(hRange) || overlaps;
        }
      });
    });

    return overlaps;
  }

  fullName(withNickname = true) {
    if (withNickname && this.nickName) return this.nickName;
    return `${this.firstName ?? ""} ${this.lastName ?? ""}`;
  }

  condensedName() {
    if (this.nickName) return this.nickName;
    return `${this.firstName ? this.firstName[0] : ""}. ${this.lastName ?? ""}`;
  }

  formattedAddress() {
    return `${this.address ?? ""}\n ${this.city ?? ""} ${this.state ?? ""} ${
      this.zipcode ?? ""
    }`;
  }

  initials() {
    let ret = "";
    if (this.firstName) ret += this.firstName[0];
    if (this.lastName) ret += this.lastName[0];
    return ret;
  }

  getStage(stagesMap): Stage_Entity {
    if (this?.mercuryStage?.id) {
      return stagesMap[this?.mercuryStage?.id ?? 0];
    }

    return readyStage;
  }

  toJson() {
    return JSON.stringify(this);
  }
}
